// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 0.4rem;
  background: #16182e;
}

.card-header {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}

.card-divider {
  height: 1px;
  background: #23263b;
}
.card-body {
  padding: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/Card.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB","sourcesContent":[".card {\r\n  border-radius: 0.4rem;\r\n  background: #16182e;\r\n}\r\n\r\n.card-header {\r\n  font-size: var(--font-md);\r\n  line-height: 2.1rem;\r\n  font-weight: normal;\r\n  letter-spacing: 0px;\r\n  color: #ffffff;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  padding: 1.5rem;\r\n}\r\n\r\n.card-divider {\r\n  height: 1px;\r\n  background: #23263b;\r\n}\r\n.card-body {\r\n  padding: 1.5rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
