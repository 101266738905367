// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Stake-note {
  text-align: center;
  margin-bottom: 0.465rem;
}

.Stake-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3.1rem;
  grid-gap: 1.5rem;
  margin-top: 0.8rem;
}

.StakeModal .Modal-content {
  width: 31rem;
}
.StakeModal .Modal-body {
  font-size: var(--font-base);
}

.Stake-warning {
  text-align: center;
  margin-top: 0.465rem;
}

@media (max-width: 800px) {
  .Stake-cards {
    grid-template-columns: 1fr;
  }
}

.DelegateGMXAlertInfo {
  text-wrap: wrap;

  a {
    color: var(--warning-yellow);
    opacity: 0.7;

    &:hover {
      color: var(--warning-yellow);
      opacity: 1;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Stake/Stake.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE,eAAe;;EAEf;IACE,4BAA4B;IAC5B,YAAY;;IAEZ;MACE,4BAA4B;MAC5B,UAAU;IACZ;EACF;AACF","sourcesContent":[".Stake-note {\r\n  text-align: center;\r\n  margin-bottom: 0.465rem;\r\n}\r\n\r\n.Stake-cards {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr;\r\n  padding: 3.1rem;\r\n  grid-gap: 1.5rem;\r\n  margin-top: 0.8rem;\r\n}\r\n\r\n.StakeModal .Modal-content {\r\n  width: 31rem;\r\n}\r\n.StakeModal .Modal-body {\r\n  font-size: var(--font-base);\r\n}\r\n\r\n.Stake-warning {\r\n  text-align: center;\r\n  margin-top: 0.465rem;\r\n}\r\n\r\n@media (max-width: 800px) {\r\n  .Stake-cards {\r\n    grid-template-columns: 1fr;\r\n  }\r\n}\r\n\r\n.DelegateGMXAlertInfo {\r\n  text-wrap: wrap;\r\n\r\n  a {\r\n    color: var(--warning-yellow);\r\n    opacity: 0.7;\r\n\r\n    &:hover {\r\n      color: var(--warning-yellow);\r\n      opacity: 1;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
