// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Suggestion-input-wrapper {
  position: relative;
}
.Suggestion-input-wrapper .Suggestion-list {
  position: absolute;
  display: flex;
  list-style: none;
  border-radius: var(--border-radius-sm);
  right: 0;
  padding: 0;
  top: 1.25rem;
  background: #212540;
  z-index: 1002;
}
.Suggestion-input-wrapper .Suggestion-list li {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  text-align: center;
}
.Suggestion-input-wrapper .Suggestion-list li:hover {
  background: rgba(58, 63, 121, 0.5607843137);
}
.Suggestion-input-wrapper .Suggestion-input {
  border: 1px solid #212540;
  border-radius: var(--border-radius-sm);
  padding-right: 0.5rem;
  line-height: 1;
  background: #212540;
}
.Suggestion-input-wrapper .Suggestion-input label {
  cursor: pointer;
}
.Suggestion-input-wrapper .Suggestion-input:hover, .Suggestion-input-wrapper .Suggestion-input:focus {
  border-color: rgba(58, 63, 121, 0.5607843137);
}
.Suggestion-input-wrapper .Suggestion-input:focus-within {
  border-color: #3a3f79;
}
.Suggestion-input-wrapper .Suggestion-input.input-error {
  border-color: #f3b50e;
}
.Suggestion-input-wrapper .Suggestion-input input {
  padding: 0.2rem 0.5rem 0.35rem;
  font-size: 1.4rem;
  border-radius: 4px;
  max-width: 7rem;
}
.Suggestion-input-wrapper .Suggestion-input span {
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/components/SuggestionInput/SuggestionInput.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,sCAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;AAEJ;AAAI;EACE,uBAAA;EACA,eAAA;EACA,sCAAA;EACA,kBAAA;AAEN;AADM;EACE,2CAAA;AAGR;AACE;EACE,yBAAA;EACA,sCAAA;EACA,qBAAA;EACA,cAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;AACN;AAEI;EAEE,6CAAA;AADN;AAGI;EACE,qBAAA;AADN;AAII;EACE,qBAAA;AAFN;AAKI;EACE,8BAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AAHN;AAKI;EACE,YAAA;AAHN","sourcesContent":[".Suggestion-input-wrapper {\r\n  position: relative;\r\n  .Suggestion-list {\r\n    position: absolute;\r\n    display: flex;\r\n    list-style: none;\r\n    border-radius: var(--border-radius-sm);\r\n    right: 0;\r\n    padding: 0;\r\n    top: 1.25rem;\r\n    background: #212540;\r\n    z-index: 1002;\r\n\r\n    li {\r\n      padding: 0.5rem 0.75rem;\r\n      cursor: pointer;\r\n      border-radius: var(--border-radius-sm);\r\n      text-align: center;\r\n      &:hover {\r\n        background: rgba(58, 63, 121, 0.5607843137254902);\r\n      }\r\n    }\r\n  }\r\n  .Suggestion-input {\r\n    border: 1px solid #212540;\r\n    border-radius: var(--border-radius-sm);\r\n    padding-right: 0.5rem;\r\n    line-height: 1;\r\n    background: #212540;\r\n\r\n    label {\r\n      cursor: pointer;\r\n    }\r\n\r\n    &:hover,\r\n    &:focus {\r\n      border-color: #3a3f798f;\r\n    }\r\n    &:focus-within {\r\n      border-color: #3a3f79;\r\n    }\r\n\r\n    &.input-error {\r\n      border-color: #f3b50e;\r\n    }\r\n\r\n    input {\r\n      padding: 0.2rem 0.5rem 0.35rem;\r\n      font-size: 1.4rem;\r\n      border-radius: 4px;\r\n      max-width: 7rem;\r\n    }\r\n    span {\r\n      opacity: 0.7;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
