// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-row {
  margin-bottom: 0rem;
}

.MarketCard-pool-balance .Tooltip {
  display: flex;
  align-items: center;
  height: 100%;
}

.MarketCard .Tooltip-popup,
.MarketCard-net-fee .Tooltip-popup {
  min-width: 32rem;
}

.MarketCard-pool-balance .Tooltip-handle {
  text-decoration: none;
}

@media (max-width: 350px) {
  .MarketCard-net-fee .Tooltip-popup {
    min-width: 26rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/MarketCard/MarketCard.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAGE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AAAJ;;AAME;;EACE,gBAAA;AAFJ;;AAMA;EACE,qBAAA;AAHF;;AAMA;EAEI;IACE,gBAAA;EAJJ;AACF","sourcesContent":[".info-row {\r\n  margin-bottom: 0rem;\r\n}\r\n\r\n.MarketCard-pool-balance {\r\n  .Tooltip {\r\n    display: flex;\r\n    align-items: center;\r\n    height: 100%;\r\n  }\r\n}\r\n\r\n.MarketCard,\r\n.MarketCard-net-fee {\r\n  .Tooltip-popup {\r\n    min-width: 32rem;\r\n  }\r\n}\r\n\r\n.MarketCard-pool-balance .Tooltip-handle {\r\n  text-decoration: none;\r\n}\r\n\r\n@media (max-width: 350px) {\r\n  .MarketCard-net-fee {\r\n    .Tooltip-popup {\r\n      min-width: 26rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
