// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExchangeBanner {
  background: linear-gradient(90deg, #105461, #28156e);
  border-radius: 0.5rem;
  margin: 3rem 3.1rem 1.5rem;
  padding: 1.2rem 2.4rem;
  padding-right: 4rem;
  position: relative;
}

.ExchangeBanner-text {
  color: white;
  font-size: 1.8rem;
  line-height: 2.3rem;
  margin: 0;
}

.ExchangeBanner-price {
  color: #50d0fe;
}

.ExchangeBanner-link {
  color: white;
}

.ExchangeBanner-close {
  position: absolute;
  top: 1.2rem;
  right: 2.4rem;
  cursor: pointer;
}

@media (max-width: 700px) {
  .ExchangeBanner {
    margin: 1.5rem;
    margin-top: 0.8rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Exchange/ExchangeBanner.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,qBAAqB;EACrB,0BAA0B;EAC1B,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,eAAe;AACjB;;AAEA;EACE;IACE,cAAc;IACd,kBAAkB;EACpB;AACF","sourcesContent":[".ExchangeBanner {\r\n  background: linear-gradient(90deg, #105461, #28156e);\r\n  border-radius: 0.5rem;\r\n  margin: 3rem 3.1rem 1.5rem;\r\n  padding: 1.2rem 2.4rem;\r\n  padding-right: 4rem;\r\n  position: relative;\r\n}\r\n\r\n.ExchangeBanner-text {\r\n  color: white;\r\n  font-size: 1.8rem;\r\n  line-height: 2.3rem;\r\n  margin: 0;\r\n}\r\n\r\n.ExchangeBanner-price {\r\n  color: #50d0fe;\r\n}\r\n\r\n.ExchangeBanner-link {\r\n  color: white;\r\n}\r\n\r\n.ExchangeBanner-close {\r\n  position: absolute;\r\n  top: 1.2rem;\r\n  right: 2.4rem;\r\n  cursor: pointer;\r\n}\r\n\r\n@media (max-width: 700px) {\r\n  .ExchangeBanner {\r\n    margin: 1.5rem;\r\n    margin-top: 0.8rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
