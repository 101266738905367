// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Orders-overview {
  font-size: var(--font-sm);
  margin: 1.5rem 3.1rem;
}

.Orders-overview-stats {
  line-height: 1.5;
}

.Orders-overview-table {
  border-collapse: collapse;
}

.Orders-overview th {
  opacity: 0.5;
  font-weight: normal;
  text-align: left;
  padding: 0.8rem 1.5rem 0.8rem 0;
}

.Orders-overview td {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.8rem 1.5rem 0.8rem 0;
}

.Orders-overview .near {
  color: orange;
}

.Orders-overview-action {
  background: none;
  border: none;
  text-decoration: underline;
  color: #fff;
}

.Orders-overview-action {
  background: none;
  border: none;
  text-decoration: underline;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/OrdersOverview/OrdersOverview.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,8CAA8C;EAC9C,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,0BAA0B;EAC1B,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,0BAA0B;EAC1B,WAAW;AACb","sourcesContent":[".Orders-overview {\r\n  font-size: var(--font-sm);\r\n  margin: 1.5rem 3.1rem;\r\n}\r\n\r\n.Orders-overview-stats {\r\n  line-height: 1.5;\r\n}\r\n\r\n.Orders-overview-table {\r\n  border-collapse: collapse;\r\n}\r\n\r\n.Orders-overview th {\r\n  opacity: 0.5;\r\n  font-weight: normal;\r\n  text-align: left;\r\n  padding: 0.8rem 1.5rem 0.8rem 0;\r\n}\r\n\r\n.Orders-overview td {\r\n  border-top: 1px solid rgba(255, 255, 255, 0.1);\r\n  padding: 0.8rem 1.5rem 0.8rem 0;\r\n}\r\n\r\n.Orders-overview .near {\r\n  color: orange;\r\n}\r\n\r\n.Orders-overview-action {\r\n  background: none;\r\n  border: none;\r\n  text-decoration: underline;\r\n  color: #fff;\r\n}\r\n\r\n.Orders-overview-action {\r\n  background: none;\r\n  border: none;\r\n  text-decoration: underline;\r\n  color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
