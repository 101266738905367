// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Actions {
  padding: 0 3.565rem;
  padding-top: 3.1rem;
}

.Actions-section {
  padding-bottom: 2rem;
  overflow-wrap: break-word;
}

.Actions-title {
  margin-bottom: 1.5rem;
}

.Actions input {
  font-size: var(--font-base);
  padding: 0;
  margin-left: 0.8rem;
}

.Actions .PositionsList .Exchange-list-action {
  display: none;
}

.Actions .PositionsList .App-button-option {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/SyntheticsActions/Actions.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;EACA,yBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,2BAAA;EACA,UAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".Actions {\r\n  padding: 0 3.565rem;\r\n  padding-top: 3.1rem;\r\n}\r\n\r\n.Actions-section {\r\n  padding-bottom: 2rem;\r\n  overflow-wrap: break-word;\r\n}\r\n\r\n.Actions-title {\r\n  margin-bottom: 1.5rem;\r\n}\r\n\r\n.Actions input {\r\n  font-size: var(--font-base);\r\n  padding: 0;\r\n  margin-left: 0.8rem;\r\n}\r\n\r\n.Actions .PositionsList .Exchange-list-action {\r\n  display: none;\r\n}\r\n\r\n.Actions .PositionsList .App-button-option {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
