// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/sparkle.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Banner-button .Banner-text {
  margin: 0;
  position: relative;
  display: inline;
  flex-wrap: wrap;
}
.Banner-button .Banner-label-last-word::after {
  content: "";
  display: inline-block;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: contain;
  width: 10px;
  height: 10px;
  position: absolute;
}
.Banner-button .Banner-text-sparkle {
  position: absolute;
  height: 10px;
  top: 0;
  right: -15px;
}
.Banner-button .Banner-action {
  background: rgba(180, 187, 255, 0.1);
  padding: 0.65rem 1.5rem;
  border-radius: var(--border-radius-sm);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 1.5;
}
.Banner-button .Banner-action:hover {
  background: rgba(80, 87, 126, 0.6);
  color: white;
}
.Banner-button .Banner-action .Banner-right-icon {
  margin-left: 1.5rem;
}

@media (max-width: 400px) {
  .Banner-button .Banner-action {
    padding: 0.5rem 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Banner/BannerButton.scss"],"names":[],"mappings":"AACE;EACE,SAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AAAJ;AAGE;EACE,WAAA;EACA,qBAAA;EACA,oEAAA;EACA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAIE;EACE,kBAAA;EACA,YAAA;EACA,MAAA;EACA,YAAA;AAFJ;AAKE;EACE,oCAAA;EACA,uBAAA;EACA,sCAAA;EACA,aAAA;EACA,gCAAA;EACA,mBAAA;EACA,eAAA;EACA,qBAAA;EACA,8BAAA;EACA,iBAAA;EACA,gBAAA;AAHJ;AAKI;EACE,kCAAA;EACA,YAAA;AAHN;AAMI;EACE,mBAAA;AAJN;;AASA;EACE;IACE,oBAAA;EANF;AACF","sourcesContent":[".Banner-button {\r\n  .Banner-text {\r\n    margin: 0;\r\n    position: relative;\r\n    display: inline;\r\n    flex-wrap: wrap;\r\n  }\r\n\r\n  .Banner-label-last-word::after {\r\n    content: \"\";\r\n    display: inline-block;\r\n    background: url(\"../../img/sparkle.svg\") no-repeat center;\r\n    background-size: contain;\r\n    width: 10px;\r\n    height: 10px;\r\n    position: absolute;\r\n  }\r\n\r\n  .Banner-text-sparkle {\r\n    position: absolute;\r\n    height: 10px;\r\n    top: 0;\r\n    right: -15px;\r\n  }\r\n\r\n  .Banner-action {\r\n    background: rgba(180, 187, 255, 0.1);\r\n    padding: 0.65rem 1.5rem;\r\n    border-radius: var(--border-radius-sm);\r\n    display: grid;\r\n    grid-template-columns: auto auto;\r\n    align-items: center;\r\n    cursor: pointer;\r\n    text-decoration: none;\r\n    justify-content: space-between;\r\n    font-size: 1.5rem;\r\n    line-height: 1.5;\r\n\r\n    &:hover {\r\n      background: rgba(80, 87, 126, 0.6);\r\n      color: white;\r\n    }\r\n\r\n    .Banner-right-icon {\r\n      margin-left: 1.5rem;\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 400px) {\r\n  .Banner-button .Banner-action {\r\n    padding: 0.5rem 1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
