// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValueTransition {
  display: inline-block;
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/components/ValueTransition/ValueTransition.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;AACF","sourcesContent":[".ValueTransition {\r\n  display: inline-block;\r\n  vertical-align: top;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
