// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeverageSlider {
  margin-top: 1.5rem;
  margin-bottom: 3.4rem;
  padding: 0 0.8rem;
}
.LeverageSlider .rc-slider-tooltip-inner {
  box-shadow: none;
  padding: 0.465rem 0.8rem;
  background: #303652;
}
.LeverageSlider .rc-slider-tooltip {
  z-index: 5;
}
.LeverageSlider .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #303652;
}
.LeverageSlider .rc-slider-rail {
  background: linear-gradient(90deg, rgb(30, 34, 61) 0%, rgb(40, 45, 74) 100%);
}
.LeverageSlider .rc-slider-track {
  background: linear-gradient(90deg, rgb(46, 61, 205) 0%, rgb(45, 66, 252) 100%);
}
.LeverageSlider .rc-slider-dot {
  border: none;
  border-radius: 1px;
  width: 2px;
  margin-left: -1px;
  background: rgb(40, 45, 74);
}
.LeverageSlider .rc-slider-dot-active {
  background: #3d51ff;
}
.LeverageSlider .rc-slider-handle {
  background: #303652;
  border: solid 2px rgb(45, 66, 252);
}
.LeverageSlider .rc-slider-handle:active {
  box-shadow: 0 0 0.4rem 0.6rem rgba(45, 66, 252, 0.2);
}
.LeverageSlider .rc-slider-mark-text,
.LeverageSlider .rc-slider-mark-text-active {
  color: white;
  opacity: 0.5;
}
.LeverageSlider .rc-slider-mark-text:hover,
.LeverageSlider .rc-slider-mark-text-active:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/LeverageSlider/LeverageSlider.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,iBAAA;AACF;AACE;EACE,gBAAA;EACA,wBAAA;EACA,mBAAA;AACJ;AAEE;EACE,UAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,4EAAA;AAFJ;AAKE;EACE,8EAAA;AAHJ;AAKE;EACE,YAAA;EACA,kBAAA;EACA,UAAA;EACA,iBAAA;EACA,2BAAA;AAHJ;AAKE;EACE,mBAAA;AAHJ;AAKE;EACE,mBAAA;EACA,kCAAA;AAHJ;AAME;EACE,oDAAA;AAJJ;AAME;;EAEE,YAAA;EACA,YAAA;AAJJ;AAOE;;EAEE,UAAA;AALJ","sourcesContent":[".LeverageSlider {\r\n  margin-top: 1.5rem;\r\n  margin-bottom: 3.4rem;\r\n  padding: 0 0.8rem;\r\n\r\n  .rc-slider-tooltip-inner {\r\n    box-shadow: none;\r\n    padding: 0.465rem 0.8rem;\r\n    background: #303652;\r\n  }\r\n\r\n  .rc-slider-tooltip {\r\n    z-index: 5;\r\n  }\r\n\r\n  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {\r\n    border-top-color: #303652;\r\n  }\r\n\r\n  .rc-slider-rail {\r\n    background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(40, 45, 74, 1) 100%);\r\n  }\r\n\r\n  .rc-slider-track {\r\n    background: linear-gradient(90deg, rgba(46, 61, 205, 1) 0%, rgba(45, 66, 252, 1) 100%);\r\n  }\r\n  .rc-slider-dot {\r\n    border: none;\r\n    border-radius: 1px;\r\n    width: 2px;\r\n    margin-left: -1px;\r\n    background: rgba(40, 45, 74, 1);\r\n  }\r\n  .rc-slider-dot-active {\r\n    background: #3d51ff;\r\n  }\r\n  .rc-slider-handle {\r\n    background: #303652;\r\n    border: solid 2px rgba(45, 66, 252, 1);\r\n  }\r\n\r\n  .rc-slider-handle:active {\r\n    box-shadow: 0 0 0.4rem 0.6rem rgba(45, 66, 252, 0.2);\r\n  }\r\n  .rc-slider-mark-text,\r\n  .rc-slider-mark-text-active {\r\n    color: white;\r\n    opacity: 0.5;\r\n  }\r\n\r\n  .rc-slider-mark-text:hover,\r\n  .rc-slider-mark-text-active:hover {\r\n    opacity: 1;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
