// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarketList-netfee-tooltip .Tooltip-popup {
  min-width: 32rem;
}

.MarketList-mobile-tvl-tooltip .Tooltip-popup {
  min-width: max-content !important;
  max-width: 100vw !important;
  white-space: nowrap;
}

@media (max-width: 350px) {
  .MarketList-netfee-tooltip .Tooltip-popup {
    min-width: 26rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/MarketsList/MarketsList.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;;AAKE;EACE,iCAAA;EACA,2BAAA;EACA,mBAAA;AAFJ;;AAMA;EAEI;IACE,gBAAA;EAJJ;AACF","sourcesContent":[".MarketList-netfee-tooltip {\r\n  .Tooltip-popup {\r\n    min-width: 32rem;\r\n  }\r\n}\r\n\r\n.MarketList-mobile-tvl-tooltip {\r\n  .Tooltip-popup {\r\n    min-width: max-content !important;\r\n    max-width: 100vw !important;\r\n    white-space: nowrap;\r\n  }\r\n}\r\n\r\n@media (max-width: 350px) {\r\n  .MarketList-netfee-tooltip {\r\n    .Tooltip-popup {\r\n      min-width: 26rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
