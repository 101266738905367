// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page-title-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 1;
  margin-bottom: 2.5rem;
}
.Page-title-wrapper.gapTop {
  margin-top: 2.5rem;
}
.Page-title-wrapper .Page-title-group {
  display: inline-flex;
  margin-bottom: 0.8rem;
}
.Page-title-wrapper .Page-title-group .Page-title__text {
  font-size: var(--font-xl);
  font-weight: 700;
  margin: 0;
}
.Page-title-wrapper .Page-title-group .Page-title__icon {
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Page-title-wrapper .Page-subtitle-group {
  color: #b7b7bd;
  line-height: 1.5;
  font-size: var(--font-base);
}

@media screen and (max-width: 1000px) {
  .Page-title-wrapper {
    margin-bottom: 1.5rem;
  }
  .Page-title-wrapper.gapTop {
    margin-top: 2rem;
  }
  .Page-title-wrapper .Page-title-group .Page-title__text {
    font-size: var(--font-lg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PageTitle/PageTitle.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,qBAAA;AACF;AACE;EACE,kBAAA;AACJ;AACE;EACE,oBAAA;EACA,qBAAA;AACJ;AAAI;EACE,yBAAA;EACA,gBAAA;EACA,SAAA;AAEN;AAAI;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEN;AACE;EACE,cAAA;EACA,gBAAA;EACA,2BAAA;AACJ;;AAGA;EACE;IACE,qBAAA;EAAF;EACE;IACE,gBAAA;EACJ;EAEI;IACE,yBAAA;EAAN;AACF","sourcesContent":[".Page-title-wrapper {\r\n  display: flex;\r\n  flex-direction: column;\r\n  line-height: 1;\r\n  margin-bottom: 2.5rem;\r\n\r\n  &.gapTop {\r\n    margin-top: 2.5rem;\r\n  }\r\n  .Page-title-group {\r\n    display: inline-flex;\r\n    margin-bottom: 0.8rem;\r\n    .Page-title__text {\r\n      font-size: var(--font-xl);\r\n      font-weight: 700;\r\n      margin: 0;\r\n    }\r\n    .Page-title__icon {\r\n      margin-left: 0.5rem;\r\n      display: flex;\r\n      justify-content: center;\r\n      align-items: center;\r\n    }\r\n  }\r\n  .Page-subtitle-group {\r\n    color: #b7b7bd;\r\n    line-height: 1.5;\r\n    font-size: var(--font-base);\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n  .Page-title-wrapper {\r\n    margin-bottom: 1.5rem;\r\n    &.gapTop {\r\n      margin-top: 2rem;\r\n    }\r\n    .Page-title-group {\r\n      .Page-title__text {\r\n        font-size: var(--font-lg);\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
