// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollateralSelector-table {
  border-collapse: collapse;
}
.CollateralSelector-collateral-logos {
  display: flex;
}
.CollateralSelector-collateral-logo-first {
  position: relative;
}
.CollateralSelector-column-pool {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.CollateralSelector-mobile-info {
  margin: unset;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/CollateralSelector/CollateralSelector.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAHJ;AAOI;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EAEA,8BAAA;AANN","sourcesContent":[".CollateralSelector {\r\n  &-table {\r\n    border-collapse: collapse;\r\n  }\r\n\r\n  &-collateral-logos {\r\n    display: flex;\r\n  }\r\n\r\n  &-collateral-logo-first {\r\n    position: relative;\r\n  }\r\n\r\n  &-column-pool {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 1rem;\r\n  }\r\n\r\n  &-mobile {\r\n    &-info {\r\n      margin: unset;\r\n      margin-top: 1rem;\r\n      display: grid;\r\n\r\n      grid-template-columns: 1fr 1fr;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
