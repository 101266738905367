// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TradeHistory-row {
  font-size: var(--font-sm);
  padding: 1.5rem;
  margin-bottom: 0.8rem;
}

.TradeHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}

.TradeHistory-item-link {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Exchange/TradeHistory.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".TradeHistory-row {\r\n  font-size: var(--font-sm);\r\n  padding: 1.5rem;\r\n  margin-bottom: 0.8rem;\r\n}\r\n\r\n.TradeHistory-time {\r\n  font-size: 1.25rem;\r\n  margin-bottom: 0.155rem;\r\n}\r\n\r\n.TradeHistory-item-link {\r\n  display: inline-block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
