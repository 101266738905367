// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search-input {
  padding: 0;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1607843137);
  position: relative;
  background: transparent;
  border-radius: var(--border-radius-sm);
}
.Search-input .Tokenselector-search-input {
  font-size: 1.6rem;
  background-position: 12px center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  padding: 1rem 1rem 1rem 4rem;
  width: 100%;
}
.Search-input .Tokenselector-search-input::placeholder {
  color: var(--dark-blue-accent);
}
.Search-input_size_s .Tokenselector-search-input {
  font-size: 1.3rem;
  padding: 0.85rem 1rem 0.85rem 3.4rem;
  background-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchInput/SearchInput.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;EACA,mDAAA;EACA,kBAAA;EACA,uBAAA;EACA,sCAAA;AACF;AACE;EACE,iBAAA;EACA,gCAAA;EACA,0BAAA;EACA,4BAAA;EACA,6BAAA;EACA,4BAAA;EACA,WAAA;AACJ;AACE;EACE,8BAAA;AACJ;AAEE;EACE,iBAAA;EACA,oCAAA;EACA,uBAAA;AAAJ","sourcesContent":[".Search-input {\r\n  padding: 0;\r\n  cursor: pointer;\r\n  border: 1px solid #ffffff29;\r\n  position: relative;\r\n  background: transparent;\r\n  border-radius: var(--border-radius-sm);\r\n\r\n  .Tokenselector-search-input {\r\n    font-size: 1.6rem;\r\n    background-position: 12px center;\r\n    background-size: 20px 20px;\r\n    background-repeat: no-repeat;\r\n    background-attachment: scroll;\r\n    padding: 1rem 1rem 1rem 4rem;\r\n    width: 100%;\r\n  }\r\n  .Tokenselector-search-input::placeholder {\r\n    color: var(--dark-blue-accent);\r\n  }\r\n\r\n  &_size_s .Tokenselector-search-input {\r\n    font-size: 1.3rem;\r\n    padding: 0.85rem 1rem 0.85rem 3.4rem;\r\n    background-size: 1.5rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
