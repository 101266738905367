// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AcceptablePriceImpactInputRow-handle {
  z-index: 1;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  text-decoration-color: rgba(255, 255, 255, 0.7);
  -webkit-user-select: none;
          user-select: none;
  color: rgba(255, 255, 255, 0.7);
}
.AcceptablePriceImpactInputRow-handle:hover {
  text-decoration-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/AcceptablePriceImpactInputRow/AcceptablePriceImpactInputRow.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;EACA,0BAAA;EACA,4BAAA;EACA,8BAAA;EACA,0BAAA;EACA,+CAAA;EACA,yBAAA;UAAA,iBAAA;EACA,+BAAA;AACF;AACE;EACE,yCAAA;EACA,yBAAA;AACJ","sourcesContent":[".AcceptablePriceImpactInputRow-handle {\r\n  z-index: 1;\r\n  cursor: pointer;\r\n  position: relative;\r\n  display: inline-flex;\r\n  text-decoration: underline;\r\n  text-decoration-style: solid;\r\n  text-decoration-thickness: 1px;\r\n  text-underline-offset: 2px;\r\n  text-decoration-color: rgba(255, 255, 255, 0.7);\r\n  user-select: none;\r\n  color: rgba(255, 255, 255, 0.7);\r\n\r\n  &:hover {\r\n    text-decoration-color: rgba(255, 255, 255);\r\n    color: rgba(255, 255, 255);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
