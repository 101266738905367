// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubaccountStatus {
  font-size: 1.4rem;
  margin: 0 0 0 0;
}

.SubaccountStatus-border {
  margin: 0 0 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1607843137);
}

.SubaccountModal-input-row {
  display: flex;
  flex: 1 1;
  margin-bottom: 1.2rem;
}
.SubaccountModal-input-row-label {
  flex: 1 1;
  align-items: center;
  display: flex;
}

.SubaccountStatus-clickable-text {
  text-decoration: underline;
  cursor: pointer;
}
.SubaccountStatus-clickable-text:hover {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/SubaccountModal/SubaccountStatus.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,0DAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,qBAAA;AACF;AACE;EACE,SAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAGA;EACE,0BAAA;EACA,eAAA;AAAF;AAEE;EACE,YAAA;AAAJ","sourcesContent":[".SubaccountStatus {\r\n  font-size: 1.4rem;\r\n  margin: 0 0 0 0;\r\n}\r\n\r\n.SubaccountStatus-border {\r\n  margin: 0 0 1rem 0;\r\n  border-bottom: 1px solid #ffffff29;\r\n}\r\n\r\n.SubaccountModal-input-row {\r\n  display: flex;\r\n  flex: 1;\r\n  margin-bottom: 1.2rem;\r\n\r\n  &-label {\r\n    flex: 1;\r\n    align-items: center;\r\n    display: flex;\r\n  }\r\n}\r\n\r\n.SubaccountStatus-clickable-text {\r\n  text-decoration: underline;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    color: white;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
