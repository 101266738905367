// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  display: grid;
  grid-row-gap: 0.5rem;
}
.order-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--dark-blue-hover);
  border-radius: var(--border-radius-sm);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-size: 1.3rem;
}

.order-list li:not(:first-child) {
  margin-bottom: 0.5rem;
}

.order-list li p {
  margin: 0;
}
.order-list li button {
  font-size: var(--font-sm);
  background: none;
  border: none;
  color: white;
  opacity: 0.8;
  display: inline-flex;
  padding: 0;
}
.order-list li button:hover {
  opacity: 1;
}

.view-orders {
  text-decoration: underline;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Exchange/ConfirmationBox.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,cAAc;EACd,aAAa;EACb,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kCAAkC;EAClC,sCAAsC;EACtC,kCAAkC;EAClC,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,SAAS;AACX;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,oBAAoB;EACpB,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB","sourcesContent":[".order-list {\r\n  list-style: none;\r\n  padding: 0;\r\n  margin: 1rem 0;\r\n  display: grid;\r\n  grid-row-gap: 0.5rem;\r\n}\r\n.order-list li {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  background: var(--dark-blue-hover);\r\n  border-radius: var(--border-radius-sm);\r\n  padding: 0.5rem 0.5rem 0.5rem 1rem;\r\n  font-size: 1.3rem;\r\n}\r\n\r\n.order-list li:not(:first-child) {\r\n  margin-bottom: 0.5rem;\r\n}\r\n\r\n.order-list li p {\r\n  margin: 0;\r\n}\r\n.order-list li button {\r\n  font-size: var(--font-sm);\r\n  background: none;\r\n  border: none;\r\n  color: white;\r\n  opacity: 0.8;\r\n  display: inline-flex;\r\n  padding: 0;\r\n}\r\n.order-list li button:hover {\r\n  opacity: 1;\r\n}\r\n\r\n.view-orders {\r\n  text-decoration: underline;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
