// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableOptionsFilter-options {
  max-height: 20rem;
  overflow-y: auto;
  overscroll-behavior: contain;
  border-top: 1px solid #32344c;
}
.TableOptionsFilter-group-name {
  font-size: var(--font-sm);
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.TableOptionsFilter-option {
  font-size: var(--font-sm);
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.TableOptionsFilter-option-in-group {
  padding-left: 25px;
}
.TableOptionsFilter-search {
  margin: 1rem;
}
.TableOptionsFilter-clear {
  font-size: var(--font-sm);
  padding: 0.5rem 1rem;
  padding-top: 1rem;
  color: var(--text-gray);
  cursor: pointer;
}
.TableOptionsFilter-clear:hover {
  color: white;
}
.TableOptionsFilter-clear:last-child {
  padding-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/TableOptionsFilter/TableOptionsFilter.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,gBAAA;EAEA,4BAAA;EAEA,6BAAA;AAFJ;AAKE;EACE,yBAAA;EAEA,aAAA;EACA,mBAAA;EACA,oBAAA;EAEA,eAAA;AALJ;AAQE;EACE,yBAAA;EAEA,aAAA;EACA,mBAAA;EACA,oBAAA;EAEA,eAAA;AARJ;AAUI;EACE,kBAAA;AARN;AAYE;EACE,YAAA;AAVJ;AAaE;EACE,yBAAA;EACA,oBAAA;EACA,iBAAA;EAEA,uBAAA;EAEA,eAAA;AAbJ;AAeI;EACE,YAAA;AAbN;AAgBI;EACE,oBAAA;AAdN","sourcesContent":[".TableOptionsFilter {\r\n  &-options {\r\n    max-height: 20rem;\r\n    overflow-y: auto;\r\n\r\n    overscroll-behavior: contain;\r\n\r\n    border-top: 1px solid #32344c;\r\n  }\r\n\r\n  &-group-name {\r\n    font-size: var(--font-sm);\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 0.5rem 1rem;\r\n\r\n    cursor: pointer;\r\n  }\r\n\r\n  &-option {\r\n    font-size: var(--font-sm);\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 0.5rem 1rem;\r\n\r\n    cursor: pointer;\r\n\r\n    &-in-group {\r\n      padding-left: 25px;\r\n    }\r\n  }\r\n\r\n  &-search {\r\n    margin: 1rem;\r\n  }\r\n\r\n  &-clear {\r\n    font-size: var(--font-sm);\r\n    padding: 0.5rem 1rem;\r\n    padding-top: 1rem;\r\n\r\n    color: var(--text-gray);\r\n\r\n    cursor: pointer;\r\n\r\n    &:hover {\r\n      color: white;\r\n    }\r\n\r\n    &:last-child {\r\n      padding-bottom: 1rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
