// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PositionDropdown-dots-icon {
  font-size: 1rem;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  display: inline-flex;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  border-radius: 2rem;
  padding: 0.5rem;
}

.PositionDropdown-dots-icon:hover {
  color: rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 0.1);
}

.PositionDropdown-extra-options {
  position: relative;
}

.PositionDropdown-extra-options .menu-items {
  top: 1rem;
  min-width: max-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/Exchange/PositionDropdown.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,+BAA+B;EAC/B,oBAAoB;EACpB,mBAAmB;EACnB,kCAAkC;EAClC,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB","sourcesContent":[".PositionDropdown-dots-icon {\r\n  font-size: 1rem;\r\n  border: none;\r\n  color: rgba(255, 255, 255, 0.7);\r\n  display: inline-flex;\r\n  align-items: center;\r\n  background: rgba(255, 255, 255, 0);\r\n  border-radius: 2rem;\r\n  padding: 0.5rem;\r\n}\r\n\r\n.PositionDropdown-dots-icon:hover {\r\n  color: rgba(255, 255, 255, 1);\r\n  background: rgba(255, 255, 255, 0.1);\r\n}\r\n\r\n.PositionDropdown-extra-options {\r\n  position: relative;\r\n}\r\n\r\n.PositionDropdown-extra-options .menu-items {\r\n  top: 1rem;\r\n  min-width: max-content;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
