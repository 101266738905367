// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-underline {
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  color: rgba(255, 255, 255, 0.7);
}
.link-underline:hover {
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/components/ExternalLink/ExternalLink.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,eAAA;EACA,oBAAA;EACA,+BAAA;AACF;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".link-underline {\r\n  text-decoration: underline;\r\n  cursor: pointer;\r\n  display: inline-flex;\r\n  color: rgba(255, 255, 255, 0.7);\r\n  &:hover {\r\n    color: rgba(255, 255, 255);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
