// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertInfo.compact {
  margin-bottom: 0.8rem;
}

.AlertInfo {
  display: flex;
  margin-bottom: 1.6rem;
}
.AlertInfo-icon {
  padding-right: 0.6rem;
  padding-top: 0.2rem;
}
.AlertInfo-icon svg {
  display: block;
}
.AlertInfo-icon svg path {
  fill: currentColor;
}
.AlertInfo-text {
  font-size: 1.3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/AlertInfo/AlertInfo.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;AACF;AACE;EACE,qBAAA;EACA,mBAAA;AACJ;AACI;EACE,cAAA;AACN;AAAM;EACE,kBAAA;AAER;AAGE;EACE,iBAAA;AADJ","sourcesContent":[".AlertInfo.compact {\r\n  margin-bottom: 0.8rem;\r\n}\r\n\r\n.AlertInfo {\r\n  display: flex;\r\n  margin-bottom: 1.6rem;\r\n\r\n  &-icon {\r\n    padding-right: 0.6rem;\r\n    padding-top: 0.2rem;\r\n\r\n    svg {\r\n      display: block;\r\n      path {\r\n        fill: currentColor;\r\n      }\r\n    }\r\n  }\r\n\r\n  &-text {\r\n    font-size: 1.3rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
