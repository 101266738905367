// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionStatus {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0;
  font-size: var(--font-sm);
}

.TransactionStatus-status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TransactionStatus-spin {
  height: 1.5rem;
  width: 1.5rem;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/TransactionStatus/TransactionStatus.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,aAAA;EACA,YAAA;AACF","sourcesContent":[".TransactionStatus {\r\n  display: flex;\r\n  width: 100%;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 0.2rem 0;\r\n  font-size: var(--font-sm);\r\n}\r\n\r\n.TransactionStatus-status {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.TransactionStatus-spin {\r\n  height: 1.5rem;\r\n  width: 1.5rem;\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
