// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GmSwapBox {
  border-radius: 0.4rem;
  padding: 1.5rem;
}

.GmSwapBox-asset-options-tabs {
  margin: 1.5rem 0;
}

.GmSwapBox-info-section {
  margin-bottom: 1.1rem;
}

.GmSwapBox-market-dropdown {
  margin-top: 1.5rem;
  margin-left: -0.5rem;
}
.GmSwapBox-market-dropdown .Dropdown-current {
  font-size: 1.5rem;
}
.GmSwapBox-market-dropdown .menu-items {
  top: 3.3rem;
}

.GmSwapBox-form-layout {
  display: flex;
  flex-direction: column;
}
.GmSwapBox-form-layout.reverse {
  flex-direction: column-reverse;
}

.GmSwapBox-warning {
  margin-bottom: 1.05rem !important;
}

.warning-tooltip {
  direction: ltr;
}
.warning-tooltip .Tooltip-handle {
  color: rgb(243, 181, 12);
  text-decoration-color: rgb(243, 181, 12);
}
.warning-tooltip .Tooltip-popup {
  color: white;
}

.gm-toast {
  line-height: 1.4;
}

@media (max-width: 900px) {
  .GmSwapBox-content {
    flex-wrap: wrap;
  }
  .GmSwapBox-content .GlpSwap-stats-card {
    margin: 0;
    margin-bottom: 3.1rem;
    max-width: 100%;
  }
  .GmSwapBox-content .GlpSwap-box {
    margin: 0;
    max-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/GmSwap/GmSwapBox/GmSwapBox.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,kBAAA;EACA,oBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,WAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,8BAAA;AADJ;;AAKA;EACE,iCAAA;AAFF;;AAKA;EACE,cAAA;AAFF;AAIE;EACE,wBAAA;EACA,wCAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;;AAOA;EACE,gBAAA;AAJF;;AAOA;EACE;IACE,eAAA;EAJF;EAOA;IACE,SAAA;IACA,qBAAA;IACA,eAAA;EALF;EAQA;IACE,SAAA;IACA,eAAA;EANF;AACF","sourcesContent":[".GmSwapBox {\r\n  border-radius: 0.4rem;\r\n  padding: 1.5rem;\r\n}\r\n\r\n.GmSwapBox-asset-options-tabs {\r\n  margin: 1.5rem 0;\r\n}\r\n\r\n.GmSwapBox-info-section {\r\n  margin-bottom: 1.1rem;\r\n}\r\n\r\n.GmSwapBox-market-dropdown {\r\n  margin-top: 1.5rem;\r\n  margin-left: -0.5rem;\r\n\r\n  .Dropdown-current {\r\n    font-size: 1.5rem;\r\n  }\r\n\r\n  .menu-items {\r\n    top: 3.3rem;\r\n  }\r\n}\r\n\r\n.GmSwapBox-form-layout {\r\n  display: flex;\r\n  flex-direction: column;\r\n\r\n  &.reverse {\r\n    flex-direction: column-reverse;\r\n  }\r\n}\r\n\r\n.GmSwapBox-warning {\r\n  margin-bottom: 1.05rem !important;\r\n}\r\n\r\n.warning-tooltip {\r\n  direction: ltr;\r\n\r\n  .Tooltip-handle {\r\n    color: rgb(243, 181, 12);\r\n    text-decoration-color: rgb(243, 181, 12);\r\n  }\r\n\r\n  .Tooltip-popup {\r\n    color: white;\r\n  }\r\n}\r\n\r\n.gm-toast {\r\n  line-height: 1.4;\r\n}\r\n\r\n@media (max-width: 900px) {\r\n  .GmSwapBox-content {\r\n    flex-wrap: wrap;\r\n  }\r\n\r\n  .GmSwapBox-content .GlpSwap-stats-card {\r\n    margin: 0;\r\n    margin-bottom: 3.1rem;\r\n    max-width: 100%;\r\n  }\r\n\r\n  .GmSwapBox-content .GlpSwap-box {\r\n    margin: 0;\r\n    max-width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
