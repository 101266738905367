// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip-row {
  display: grid;
  margin: 0 0 0.75rem 0;
  grid-template-columns: 1fr auto;
}

.Tooltip-row .Tooltip-row-label {
  margin-right: 0.5rem;
}

.Tooltip-row .Tooltip-row-value {
  text-align: right;
  word-break: break-all;
}

.Tooltip-row-values {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Tooltip-row-values li {
  padding: 0.25rem 0 0 0;
  text-align: right;
}

.Tooltip-number {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/StatsTooltip/StatsTooltip.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,+BAA+B;AACjC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".Tooltip-row {\r\n  display: grid;\r\n  margin: 0 0 0.75rem 0;\r\n  grid-template-columns: 1fr auto;\r\n}\r\n\r\n.Tooltip-row .Tooltip-row-label {\r\n  margin-right: 0.5rem;\r\n}\r\n\r\n.Tooltip-row .Tooltip-row-value {\r\n  text-align: right;\r\n  word-break: break-all;\r\n}\r\n\r\n.Tooltip-row-values {\r\n  list-style: none;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.Tooltip-row-values li {\r\n  padding: 0.25rem 0 0 0;\r\n  text-align: right;\r\n}\r\n\r\n.Tooltip-number {\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
