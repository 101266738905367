// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddressView {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.AddressView-trader-id {
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 1.5rem;
  text-decoration: none;
}

.AddressView-ens-avatar {
  display: inline-block;
  border-radius: 50%;
  margin: 0;
  border: 0;
  padding: 0;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/components/AddressView/AddressView.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,qBAAA;AACF;;AAEA;EACE,qBAAA;EACA,mBAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;AACF","sourcesContent":[".AddressView {\r\n  display: inline-flex;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  text-decoration: none;\r\n}\r\n\r\n.AddressView-trader-id {\r\n  display: inline-block;\r\n  margin-left: 0.5rem;\r\n  font-size: 1.5rem;\r\n  text-decoration: none;\r\n}\r\n\r\n.AddressView-ens-avatar {\r\n  display: inline-block;\r\n  border-radius: 50%;\r\n  margin: 0;\r\n  border: 0;\r\n  padding: 0;\r\n  overflow: hidden;\r\n  background-size: cover;\r\n  background-position: center;\r\n  background-repeat: no-repeat;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
