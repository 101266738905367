// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValidationRow {
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: var(--font-base);
}

.ValidationRow-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ValidationRow-icon {
  margin-right: 1.5rem;
}

.BeginAccountTransfer .ValidationRow {
  margin-bottom: 1.5rem;
  font-size: var(--font-base);
}

.BeginAccountTransfer-validations {
  margin-bottom: 2.325rem;
}

.BeginAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
  font-size: var(--font-base);
}

.BeginAccountTransfer .soft-error {
  color: #b7b7bd;
  line-height: 2.15rem;
  font-size: 14px;
  margin: -0.5rem 0 1rem 3rem;
}

.VestedAffiliate-checkbox {
  margin-bottom: 1.5rem;
  margin-left: 3rem;
  align-items: flex-start;
}

.VestedAffiliate-checkbox > .Checkbox-icon-wrapper {
  align-items: flex-start;
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/BeginAccountTransfer/BeginAccountTransfer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".ValidationRow {\r\n  display: grid;\r\n  grid-template-columns: auto 1fr;\r\n  font-size: var(--font-base);\r\n}\r\n\r\n.ValidationRow-icon-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.ValidationRow-icon {\r\n  margin-right: 1.5rem;\r\n}\r\n\r\n.BeginAccountTransfer .ValidationRow {\r\n  margin-bottom: 1.5rem;\r\n  font-size: var(--font-base);\r\n}\r\n\r\n.BeginAccountTransfer-validations {\r\n  margin-bottom: 2.325rem;\r\n}\r\n\r\n.BeginAccountTransfer .Modal .App-cta {\r\n  display: block;\r\n  text-align: center;\r\n  font-size: var(--font-base);\r\n}\r\n\r\n.BeginAccountTransfer .soft-error {\r\n  color: #b7b7bd;\r\n  line-height: 2.15rem;\r\n  font-size: 14px;\r\n  margin: -0.5rem 0 1rem 3rem;\r\n}\r\n\r\n.VestedAffiliate-checkbox {\r\n  margin-bottom: 1.5rem;\r\n  margin-left: 3rem;\r\n  align-items: flex-start;\r\n}\r\n\r\n.VestedAffiliate-checkbox > .Checkbox-icon-wrapper {\r\n  align-items: flex-start;\r\n  margin-top: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
