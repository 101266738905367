// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClaimSettleModal-divider {
  margin-top: 1.5rem;
}

.ClaimSettleModal-alert {
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 1rem;
}

.ClaimSettleModal-modal-content {
  margin-top: 1.8rem;
}

.ClaimSettleModal-row-text {
  color: white;
}

.ClaimSettleModal-info-row {
  display: flex;
  font-size: var(--font-sm);
  margin-bottom: 1.5rem;
  align-items: baseline;
}

.ClaimSettleModal-info-label-usd {
  margin-left: auto;
}

.ClaimSettleModal-header {
  margin-bottom: 1.5rem;
  display: flex;
  color: rgba(255, 255, 255, 0.7);
  font-size: var(--font-sm);
}

.ClaimSettleModal-header-left {
  margin-left: 2.3rem;
}

.ClaimSettleModal-header-right {
  margin-left: auto;
}

.ClaimSettleModal-checkbox {
  margin-right: 0.5rem;
}

.ClaimSettleModal-tooltip .Tooltip-popup {
  min-width: 0;
  white-space: nowrap;
}

.ClaimSettleModal-checkbox-label {
  margin-left: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/SettleAccruedFundingFeeModal/SettleAccruedFundingFeeModal.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,+BAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,qBAAA;EACA,qBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,qBAAA;EACA,aAAA;EACA,+BAAA;EACA,yBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AACA;EACE,oBAAA;AAEF;;AAEE;EACE,YAAA;EACA,mBAAA;AACJ;;AAGA;EACE,mBAAA;AAAF","sourcesContent":[".ClaimSettleModal-divider {\r\n  margin-top: 1.5rem;\r\n}\r\n\r\n.ClaimSettleModal-alert {\r\n  color: rgba(255, 255, 255, 0.7);\r\n  padding-bottom: 1rem;\r\n}\r\n\r\n.ClaimSettleModal-modal-content {\r\n  margin-top: 1.8rem;\r\n}\r\n\r\n.ClaimSettleModal-row-text {\r\n  color: white;\r\n}\r\n\r\n.ClaimSettleModal-info-row {\r\n  display: flex;\r\n  font-size: var(--font-sm);\r\n  margin-bottom: 1.5rem;\r\n  align-items: baseline;\r\n}\r\n\r\n.ClaimSettleModal-info-label-usd {\r\n  margin-left: auto;\r\n}\r\n\r\n.ClaimSettleModal-header {\r\n  margin-bottom: 1.5rem;\r\n  display: flex;\r\n  color: rgba(255, 255, 255, 0.7);\r\n  font-size: var(--font-sm);\r\n}\r\n\r\n.ClaimSettleModal-header-left {\r\n  margin-left: 2.3rem;\r\n}\r\n.ClaimSettleModal-header-right {\r\n  margin-left: auto;\r\n}\r\n\r\n.ClaimSettleModal-checkbox {\r\n  margin-right: 0.5rem;\r\n}\r\n\r\n.ClaimSettleModal-tooltip {\r\n  .Tooltip-popup {\r\n    min-width: 0;\r\n    white-space: nowrap;\r\n  }\r\n}\r\n\r\n.ClaimSettleModal-checkbox-label {\r\n  margin-left: 0.4rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
