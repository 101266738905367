// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ecosystem-projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
}

@media (max-width: 1000px) {
  .Ecosystem-projects {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .Ecosystem-projects {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Ecosystem/Ecosystem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".Ecosystem-projects {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr 1fr;\r\n  grid-gap: 1.5rem;\r\n}\r\n\r\n@media (max-width: 1000px) {\r\n  .Ecosystem-projects {\r\n    grid-template-columns: 1fr 1fr;\r\n  }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .Ecosystem-projects {\r\n    grid-template-columns: 1fr;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
