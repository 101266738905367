// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-token-selector {
  padding-left: 0.4rem;
  margin-right: 0.5rem;
}
.chart-token-selector .Dropdown-current {
  font-size: 2.1rem;
  font-weight: 700;
  color: white;
}
.chart-token-selector .Dropdown-current-arrow {
  font-size: var(--font-sm);
  color: hsla(0deg, 0%, 100%, 0.7);
  margin-left: 1rem;
  margin-top: -2px;
}
.chart-token-selector .Dropdown-options {
  width: 15.8rem;
  top: 4.6rem;
  left: -0.6rem;
}
.chart-token-selector .Dropdown-option {
  font-size: var(--font-sm);
  height: 3.4rem;
  font-weight: 700;
}

.TVChart-top-card {
  height: 58px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/TVChart/TVChart.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,oBAAA;AACF;AACE;EACE,iBAAA;EACA,gBAAA;EACA,YAAA;AACJ;AAEE;EACE,yBAAA;EACA,gCAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,cAAA;EACA,WAAA;EACA,aAAA;AADJ;AAIE;EACE,yBAAA;EACA,cAAA;EACA,gBAAA;AAFJ;;AAMA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AAHF","sourcesContent":[".chart-token-selector {\r\n  padding-left: 0.4rem;\r\n  margin-right: 0.5rem;\r\n\r\n  .Dropdown-current {\r\n    font-size: 2.1rem;\r\n    font-weight: 700;\r\n    color: white;\r\n  }\r\n\r\n  .Dropdown-current-arrow {\r\n    font-size: var(--font-sm);\r\n    color: hsla(0, 0%, 100%, 0.7);\r\n    margin-left: 1rem;\r\n    margin-top: -2px;\r\n  }\r\n\r\n  .Dropdown-options {\r\n    width: 15.8rem;\r\n    top: 4.6rem;\r\n    left: -0.6rem;\r\n  }\r\n\r\n  .Dropdown-option {\r\n    font-size: var(--font-sm);\r\n    height: 3.4rem;\r\n    font-weight: 700;\r\n  }\r\n}\r\n\r\n.TVChart-top-card {\r\n  height: 58px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
