// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-loading-skeleton::after {
  background: linear-gradient(to right, transparent, rgba(180, 187, 255, 0.1019607843), transparent);
  animation-duration: 2s;
}

@keyframes react-loading-skeleton {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Skeleton/Skeleton.scss"],"names":[],"mappings":"AAAA;EACE,kGAAA;EACA,sBAAA;AACF;;AAEA;EACE;IACE,4BAAA;EACF;EACA;IACE,2BAAA;EACF;EACA;IACE,2BAAA;EACF;AACF","sourcesContent":[".react-loading-skeleton::after {\r\n  background: linear-gradient(to right, transparent, #b4bbff1a, transparent);\r\n  animation-duration: 2s;\r\n}\r\n\r\n@keyframes react-loading-skeleton {\r\n  0% {\r\n    transform: translateX(-100%);\r\n  }\r\n  60% {\r\n    transform: translateX(100%);\r\n  }\r\n  100% {\r\n    transform: translateX(100%);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
