// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-settings-row {
  margin-bottom: 0.8rem;
  font-size: var(--font-base);
}

.App-settings .App-cta {
  margin-top: 1.5rem;
}

.App-settings .Modal-content {
  width: 40rem;
}

.App-slippage-tolerance-input-container {
  position: relative;
}

.App-slippage-tolerance-input {
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  width: 100%;
}
.App-slippage-tolerance-input.with-minus {
  padding-left: 2.5rem;
}

.App-slippage-tolerance-input-minus {
  position: absolute;
  left: 1.1rem;
  top: 2.325rem;
}

.App-slippage-tolerance-input-percent {
  position: absolute;
  right: 1.1rem;
  width: 3.1rem;
  top: 2.325rem;
  bottom: 0;
  text-align: right;
}

.settings-modal-error {
  padding-bottom: 0.7rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SettingsModal/SettingsModal.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,2BAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAGE;EACE,YAAA;AAAJ;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,0CAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;AADF;AAGE;EACE,oBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;AAFF;;AAKA;EACE,kBAAA;EACA,aAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;EACA,iBAAA;AAFF;;AAKA;EACE,sBAAA;AAFF","sourcesContent":[".App-settings-row {\r\n  margin-bottom: 0.8rem;\r\n  font-size: var(--font-base);\r\n}\r\n\r\n.App-settings .App-cta {\r\n  margin-top: 1.5rem;\r\n}\r\n\r\n.App-settings {\r\n  .Modal-content {\r\n    width: 40rem;\r\n  }\r\n}\r\n\r\n.App-slippage-tolerance-input-container {\r\n  position: relative;\r\n}\r\n\r\n.App-slippage-tolerance-input {\r\n  border: 1px solid rgba(255, 255, 255, 0.1);\r\n  margin-top: 0.8rem;\r\n  margin-bottom: 0.8rem;\r\n  width: 100%;\r\n\r\n  &.with-minus {\r\n    padding-left: 2.5rem;\r\n  }\r\n}\r\n\r\n.App-slippage-tolerance-input-minus {\r\n  position: absolute;\r\n  left: 1.1rem;\r\n  top: 2.325rem;\r\n}\r\n\r\n.App-slippage-tolerance-input-percent {\r\n  position: absolute;\r\n  right: 1.1rem;\r\n  width: 3.1rem;\r\n  top: 2.325rem;\r\n  bottom: 0;\r\n  text-align: right;\r\n}\r\n\r\n.settings-modal-error {\r\n  padding-bottom: 0.7rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
