// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TradeFeesRow {
  opacity: 1;
}
.TradeFeesRow .Exchange-info-label {
  opacity: 1;
  color: rgba(255, 255, 255, 0.7);
}

.TradeFeesRow-tooltip .Tooltip-popup {
  min-width: 32rem;
}
.TradeFeesRow-tooltip .Tooltip-popup .Tooltip-row:not(:last-child) {
  margin-bottom: 0.5rem;
}

.TradeFeesRow-warning-tooltip .Tooltip-handle {
  color: rgb(243, 181, 12);
  text-decoration-color: rgb(243, 181, 12);
}
.TradeFeesRow-warning-tooltip .Tooltip-popup {
  color: white;
}

@media (max-width: 700px) {
  .TradeFeesRow-tooltip .Tooltip-popup {
    min-width: 31rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/TradeFeesRow/TradeFeesRow.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EACE,UAAA;EACA,+BAAA;AACJ;;AAIE;EACE,gBAAA;AADJ;AAGI;EACE,qBAAA;AADN;;AAOE;EACE,wBAAA;EACA,wCAAA;AAJJ;AAOE;EACE,YAAA;AALJ;;AASA;EAEI;IACE,gBAAA;EAPJ;AACF","sourcesContent":[".TradeFeesRow {\r\n  opacity: 1;\r\n\r\n  .Exchange-info-label {\r\n    opacity: 1;\r\n    color: rgba(255, 255, 255, 0.7);\r\n  }\r\n}\r\n\r\n.TradeFeesRow-tooltip {\r\n  .Tooltip-popup {\r\n    min-width: 32rem;\r\n\r\n    .Tooltip-row:not(:last-child) {\r\n      margin-bottom: 0.5rem;\r\n    }\r\n  }\r\n}\r\n\r\n.TradeFeesRow-warning-tooltip {\r\n  .Tooltip-handle {\r\n    color: rgb(243, 181, 12);\r\n    text-decoration-color: rgb(243, 181, 12);\r\n  }\r\n\r\n  .Tooltip-popup {\r\n    color: white;\r\n  }\r\n}\r\n\r\n@media (max-width: 700px) {\r\n  .TradeFeesRow-tooltip {\r\n    .Tooltip-popup {\r\n      min-width: 31rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
