// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Stats {
  margin: 20px;
  text-align: left;
}

.Stats td,
.Stats th {
  padding: 10px 7px;
  position: relative;
  vertical-align: top;
}

.Stats td .Stats-share-bar {
  background: rgba(255, 255, 255, 0.25);
  position: relative;
  margin-top: 5px;
  height: 2px;
}
.Stats td .Stats-share-bar-fill {
  height: 100%;
  background: #fff;
}
.Stats .warn .Stats-share-bar-fill {
  background: orange;
}

.Stats .warn {
  color: orange;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Stats/Stats.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".Stats {\r\n  margin: 20px;\r\n  text-align: left;\r\n}\r\n\r\n.Stats td,\r\n.Stats th {\r\n  padding: 10px 7px;\r\n  position: relative;\r\n  vertical-align: top;\r\n}\r\n\r\n.Stats td .Stats-share-bar {\r\n  background: rgba(255, 255, 255, 0.25);\r\n  position: relative;\r\n  margin-top: 5px;\r\n  height: 2px;\r\n}\r\n.Stats td .Stats-share-bar-fill {\r\n  height: 100%;\r\n  background: #fff;\r\n}\r\n.Stats .warn .Stats-share-bar-fill {\r\n  background: orange;\r\n}\r\n\r\n.Stats .warn {\r\n  color: orange;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
