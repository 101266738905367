// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.Checkbox .Checkbox-icon {
  font-size: 1.25rem;
  color: rgba(61, 81, 255, 1);
  margin-right: 0.62rem;
  margin-bottom: 0;
}

.Checkbox.fullRow .Checkbox-icon {
  margin-right: 0;
}

.Checkbox.disabled .Checkbox-icon {
  color: #47509e;
}

.Checkbox-icon.inactive {
  color: rgba(255, 255, 255, 0.7);
}

.Checkbox-label {
  display: inline-block;
  vertical-align: middle;
  font-size: var(--font-sm);
  text-align: left;
}

.Checkbox.fullRow {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Checkbox/Checkbox.css"],"names":[],"mappings":"AAAA;EACE,yBAAiB;UAAjB,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,+BAA+B;EAC/B,cAAc;EACd,yBAAyB;EACzB,uBAAuB;AACzB","sourcesContent":[".Checkbox {\r\n  user-select: none;\r\n  cursor: pointer;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.Checkbox.disabled {\r\n  cursor: default;\r\n  pointer-events: none;\r\n}\r\n\r\n.Checkbox-icon-wrapper {\r\n  display: inline-flex;\r\n  align-items: center;\r\n}\r\n\r\n.Checkbox .Checkbox-icon {\r\n  font-size: 1.25rem;\r\n  color: rgba(61, 81, 255, 1);\r\n  margin-right: 0.62rem;\r\n  margin-bottom: 0;\r\n}\r\n\r\n.Checkbox.fullRow .Checkbox-icon {\r\n  margin-right: 0;\r\n}\r\n\r\n.Checkbox.disabled .Checkbox-icon {\r\n  color: #47509e;\r\n}\r\n\r\n.Checkbox-icon.inactive {\r\n  color: rgba(255, 255, 255, 0.7);\r\n}\r\n\r\n.Checkbox-label {\r\n  display: inline-block;\r\n  vertical-align: middle;\r\n  font-size: var(--font-sm);\r\n  text-align: left;\r\n}\r\n\r\n.Checkbox.fullRow {\r\n  width: 100%;\r\n  display: grid;\r\n  grid-template-columns: 1fr auto;\r\n  direction: rtl;\r\n  font-size: var(--font-sm);\r\n  margin-bottom: 0.465rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
