// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClaimableModal .Modal-body {
  overflow-x: hidden;
}

.ClaimModal-content {
  font-size: var(--font-sm);
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.ClaimModal-row {
  margin-bottom: 0rem;
}

.ClaimModal-row-tooltip .Tooltip-popup {
  min-width: 0;
}

.ClaimModal-divider {
  margin-bottom: 2rem;
}

.ClaimSettleModal-tooltip-text-grey {
  color: var(--text-gray);
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/ClaimModal/ClaimModal.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;;AAIA;EACE,yBAAA;EACA,gBAAA;EACA,qBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAKE;EACE,YAAA;AAFJ;;AAMA;EACE,mBAAA;AAHF;;AAMA;EACE,uBAAA;AAHF","sourcesContent":[".ClaimableModal {\r\n  .Modal-body {\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n.ClaimModal-content {\r\n  font-size: var(--font-sm);\r\n  margin-top: 3rem;\r\n  margin-bottom: 1.5rem;\r\n}\r\n\r\n.ClaimModal-row {\r\n  margin-bottom: 0rem;\r\n}\r\n\r\n.ClaimModal-row-tooltip {\r\n  .Tooltip-popup {\r\n    min-width: 0;\r\n  }\r\n}\r\n\r\n.ClaimModal-divider {\r\n  margin-bottom: 2rem;\r\n}\r\n\r\n.ClaimSettleModal-tooltip-text-grey {\r\n  color: var(--text-gray);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
