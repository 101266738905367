// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GmFees-tooltip .Tooltip-popup {
  min-width: 32rem;
}

@media (max-width: 700px) {
  .GmFees-tooltip .Tooltip-popup {
    min-width: 28rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/GmSwap/GmFees/GmFees.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;;AAIA;EAEI;IACE,gBAAA;EAFJ;AACF","sourcesContent":[".GmFees-tooltip {\r\n  .Tooltip-popup {\r\n    min-width: 32rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 700px) {\r\n  .GmFees-tooltip {\r\n    .Tooltip-popup {\r\n      min-width: 28rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
