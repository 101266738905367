// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarketSelector.side-menu .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.collaterals-logo {
  display: flex;
}
.collaterals-logo .collateral-logo-first {
  position: relative;
}
.collaterals-logo .collateral-logo-second {
  margin-left: -2rem;
}

@media (max-width: 1100px) {
  .MarketSelector.side-menu .Modal {
    align-items: center;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MarketSelector/MarketSelector.scss"],"names":[],"mappings":"AAEI;EACE,uBAAA;EACA,yBAAA;AADN;;AAMA;EACE,aAAA;AAHF;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;;AAQA;EAGM;IACE,mBAAA;IACA,uBAAA;EAPN;AACF","sourcesContent":[".MarketSelector {\r\n  &.side-menu {\r\n    .Modal {\r\n      align-items: flex-start;\r\n      justify-content: flex-end;\r\n    }\r\n  }\r\n}\r\n\r\n.collaterals-logo {\r\n  display: flex;\r\n\r\n  .collateral-logo-first {\r\n    position: relative;\r\n  }\r\n\r\n  .collateral-logo-second {\r\n    margin-left: -2rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 1100px) {\r\n  .MarketSelector {\r\n    &.side-menu {\r\n      .Modal {\r\n        align-items: center;\r\n        justify-content: center;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
