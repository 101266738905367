// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -1.6rem, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 1.6rem;
  height: 1.6rem;
  margin: 4.65rem 0.31rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/Loader.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,qCAAqC;EACvC;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,kDAAkD;AACpD;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@keyframes bouncing-loader {\r\n  to {\r\n    opacity: 0.1;\r\n    transform: translate3d(0, -1.6rem, 0);\r\n  }\r\n}\r\n\r\n.bouncing-loader {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.bouncing-loader > div {\r\n  width: 1.6rem;\r\n  height: 1.6rem;\r\n  margin: 4.65rem 0.31rem;\r\n  background: #8385aa;\r\n  border-radius: 50%;\r\n  animation: bouncing-loader 0.6s infinite alternate;\r\n}\r\n\r\n.bouncing-loader > div:nth-child(2) {\r\n  animation-delay: 0.2s;\r\n}\r\n\r\n.bouncing-loader > div:nth-child(3) {\r\n  animation-delay: 0.4s;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
