// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarketFilterLongShort-container {
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.MarketFilterLongShort-container .Tab-option {
  padding: 6px 10px !important;
  flex: 1 1;
  line-height: 1;
}

.MarketFilterLongShort-tabs {
  grid-template-columns: 1fr 1fr 1fr !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/TableMarketFilter/MarketFilterLongShort.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,4BAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,6CAAA;AACF","sourcesContent":[".MarketFilterLongShort-container {\r\n  padding-bottom: 1rem;\r\n  padding-left: 1rem;\r\n  padding-right: 1rem;\r\n}\r\n\r\n.MarketFilterLongShort-container .Tab-option {\r\n  padding: 6px 10px !important;\r\n  flex: 1;\r\n  line-height: 1;\r\n}\r\n\r\n.MarketFilterLongShort-tabs {\r\n  grid-template-columns: 1fr 1fr 1fr !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
