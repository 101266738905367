// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClaimHistoryRow-external-link {
  vertical-align: top;
}
.ClaimHistoryRow-external-link svg path {
  fill: currentColor;
}

.ClaimHistoryRow-tooltip-row {
  white-space: nowrap;
  margin-bottom: 0.5rem;
}

.ClaimHistoryRow-time {
  font-size: 1.25rem;
}

.ClaimHistoryRow-size {
  text-align: end !important;
}

.ClaimHistoryRow-action-handle {
  display: block;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ClaimHistoryRow-size-tooltip-portal .Tooltip-popup {
  min-width: unset !important;
  width: max-content;
  max-width: unset !important;
}

.ClaimHistoryRow-tooltip-portal .Tooltip-popup {
  min-width: max-content;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/Claims/ClaimHistoryRow/ClaimHistoryRow.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEI;EACE,kBAAA;AAAN;;AAKA;EACE,mBAAA;EACA,qBAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,0BAAA;AAFF;;AAKA;EACE,cAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,2BAAA;EACA,kBAAA;EACA,2BAAA;AAFF;;AAKA;EACE,sBAAA;AAFF","sourcesContent":[".ClaimHistoryRow-external-link {\r\n  vertical-align: top;\r\n\r\n  svg {\r\n    path {\r\n      fill: currentColor;\r\n    }\r\n  }\r\n}\r\n\r\n.ClaimHistoryRow-tooltip-row {\r\n  white-space: nowrap;\r\n  margin-bottom: 0.5rem;\r\n}\r\n\r\n.ClaimHistoryRow-time {\r\n  font-size: 1.25rem;\r\n}\r\n\r\n.ClaimHistoryRow-size {\r\n  text-align: end !important;\r\n}\r\n\r\n.ClaimHistoryRow-action-handle {\r\n  display: block;\r\n  min-width: 0px;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  white-space: nowrap;\r\n}\r\n\r\n.ClaimHistoryRow-size-tooltip-portal .Tooltip-popup {\r\n  min-width: unset !important;\r\n  width: max-content;\r\n  max-width: unset !important;\r\n}\r\n\r\n.ClaimHistoryRow-tooltip-portal .Tooltip-popup {\r\n  min-width: max-content;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
