// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarketPoolsPage-content {
  display: flex;
  justify-content: space-between;
}

.MarketPoolsPage-content .MarketPoolsPage-swap-box {
  max-width: 47.95rem;
  width: 100%;
}

@media (max-width: 900px) {
  .MarketPoolsPage-content {
    flex-wrap: wrap;
  }
  .MarketPoolsPage-content .MarketPoolsPage-swap-box {
    max-width: none;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/MarketPoolsPage/MarketPoolsPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,mBAAA;EACA,WAAA;AACF;;AAEA;EACE;IACE,eAAA;EACF;EAEA;IACE,eAAA;IACA,WAAA;EAAF;AACF","sourcesContent":[".MarketPoolsPage-content {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.MarketPoolsPage-content .MarketPoolsPage-swap-box {\r\n  max-width: 47.95rem;\r\n  width: 100%;\r\n}\r\n\r\n@media (max-width: 900px) {\r\n  .MarketPoolsPage-content {\r\n    flex-wrap: wrap;\r\n  }\r\n\r\n  .MarketPoolsPage-content .MarketPoolsPage-swap-box {\r\n    max-width: none;\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
