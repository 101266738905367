// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  margin-bottom: 7rem;
}

.page-not-found {
  text-align: center;
}
.page-not-found img {
  max-width: 225px;
}
.go-back span {
  color: #a0a3c4;
}
.go-back a {
  color: white;
}

@media (max-width: 500px) {
  .page-not-found img {
    max-width: 200px;
  }
  .page-not-found {
    margin-top: 2.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/PageNotFound/PageNotFound.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,YAAY;AACd;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".page-not-found-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  min-height: 60vh;\r\n  margin-bottom: 7rem;\r\n}\r\n\r\n.page-not-found {\r\n  text-align: center;\r\n}\r\n.page-not-found img {\r\n  max-width: 225px;\r\n}\r\n.go-back span {\r\n  color: #a0a3c4;\r\n}\r\n.go-back a {\r\n  color: white;\r\n}\r\n\r\n@media (max-width: 500px) {\r\n  .page-not-found img {\r\n    max-width: 200px;\r\n  }\r\n  .page-not-found {\r\n    margin-top: 2.5rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
