// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NetFeeTooltip {
  width: 100%;
}
.NetFeeTooltip-head th {
  color: var(--text-gray);
  font-weight: normal;
  text-transform: uppercase;
  padding-bottom: 1rem;
}
.NetFeeTooltip-cell-right {
  text-align: right;
}
.NetFeeTooltip-cell-center {
  text-align: right;
  padding-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/MarketsList/NetFeeTooltip.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,oBAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,mBAAA;AADJ","sourcesContent":[".NetFeeTooltip {\r\n  width: 100%;\r\n\r\n  &-head th {\r\n    color: var(--text-gray);\r\n    font-weight: normal;\r\n    text-transform: uppercase;\r\n    padding-bottom: 1rem;\r\n  }\r\n\r\n  &-cell-right {\r\n    text-align: right;\r\n  }\r\n\r\n  &-cell-center {\r\n    text-align: right;\r\n    padding-right: 1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
