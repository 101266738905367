// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rebate-amount-value {
  display: flex;
  align-items: center;
}

.Rebate-amount .Tooltip-popup {
  min-width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Referrals/TradersStats.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAGE;EACE,gBAAA;AAAJ","sourcesContent":[".Rebate-amount-value {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.Rebate-amount {\r\n  .Tooltip-popup {\r\n    min-width: 20rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
