import { createClient } from "./utils";
import { ARBITRUM, ARBITRUM_GOERLI, AVALANCHE, AVALANCHE_FUJI, ETH_MAINNET, HARMONY } from "config/chains";

export const chainlinkClient = createClient(ETH_MAINNET, "chainLink");

export const arbitrumGraphClient = createClient(ARBITRUM, "stats");
export const harmonyGraphClient = createClient(HARMONY, "stats");
export const arbitrumReferralsGraphClient = createClient(ARBITRUM, "referrals");
export const harmonyReferralsGraphClient = createClient(HARMONY, "referrals");
export const arbitrumGoerliReferralsGraphClient = createClient(ARBITRUM_GOERLI, "referrals");
export const nissohGraphClient = createClient(ARBITRUM, "nissohVault");

export const avalancheGraphClient = createClient(AVALANCHE, "stats");
export const avalancheReferralsGraphClient = createClient(AVALANCHE, "referrals");
export const avalancheFujiReferralsGraphClient = createClient(AVALANCHE_FUJI, "referrals");

export const arbitrumSyntheticsStatsClient = createClient(ARBITRUM, "syntheticsStats");
export const avalancheSyntheticsStatsClient = createClient(AVALANCHE, "syntheticsStats");
export const avalancheFujiSyntheticsStatsClient = createClient(AVALANCHE_FUJI, "syntheticsStats");
export const arbitrumGoerliSyntheticsStatsClient = createClient(ARBITRUM_GOERLI, "syntheticsStats");

export const arbitrumSubsquidClient = createClient(ARBITRUM, "subsquid");
export const avalancheSubsquidClient = createClient(AVALANCHE, "subsquid");
export const avalancheFujiSubsquidClient = createClient(AVALANCHE_FUJI, "subsquid");
export const arbitrumGoerliSubsquidClient = createClient(ARBITRUM_GOERLI, "subsquid");

export const harmonySubsquidClient = createClient(HARMONY, "subsquid");

export function getSyntheticsGraphClient(chainId: number) {
  if (chainId === HARMONY) {
    return harmonyReferralsGraphClient;
  }

  // if (chainId === ARBITRUM) {
  //   return arbitrumSyntheticsStatsClient;
  // }

  // if (chainId === AVALANCHE) {
  //   return avalancheSyntheticsStatsClient;
  // }

  // if (chainId === AVALANCHE_FUJI) {
  //   return avalancheFujiSyntheticsStatsClient;
  // }

  // if (chainId === ARBITRUM_GOERLI) {
  //   return arbitrumGoerliSyntheticsStatsClient;
  // }

  return null;
}

export function getSubsquidGraphClient(chainId: number) {
  if (chainId === HARMONY) {
    return harmonySubsquidClient;
  }

  // if (chainId === ARBITRUM) {
  //   return arbitrumSubsquidClient;
  // }

  // if (chainId === AVALANCHE) {
  //   return avalancheSubsquidClient;
  // }

  // if (chainId === AVALANCHE_FUJI) {
  //   return avalancheFujiSubsquidClient;
  // }

  // if (chainId === ARBITRUM_GOERLI) {
  //   return arbitrumGoerliSubsquidClient;
  // }

  // return null;
}

export function getGmxGraphClient(chainId: number) {
  if (chainId === HARMONY) {
    return harmonyGraphClient
  }

  // if (chainId === ARBITRUM) {
  //   return arbitrumGraphClient;
  // } else if (chainId === AVALANCHE) {
  //   return avalancheGraphClient;
  // } else if (chainId === ARBITRUM_GOERLI || chainId === AVALANCHE_FUJI) {
  //   return null;
  // }

  throw new Error(`Unsupported chain ${chainId}`);
}

export function getReferralsGraphClient(chainId) {
  if(chainId === HARMONY) {
    return arbitrumReferralsGraphClient;
  }

  // if (chainId === ARBITRUM) {
  //   return arbitrumReferralsGraphClient;
  // } else if (chainId === AVALANCHE) {
  //   return avalancheReferralsGraphClient;
  // } else if (chainId === AVALANCHE_FUJI) {
  //   return avalancheFujiReferralsGraphClient;
  // } else if (chainId === ARBITRUM_GOERLI) {
  //   return arbitrumGoerliReferralsGraphClient;
  // }
  throw new Error(`Unsupported chain ${chainId}`);
}
