// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderPromoBanner {
  background: #204891;
  padding: 1.2rem 2.4rem;
  padding-right: 4rem;
  text-align: center;
  position: relative;
}

.HeaderPromoBanner .cross-icon {
  position: absolute;
  right: 1.4rem;
  top: 50%;
  margin-top: -1.1rem;
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  margin-left: 1.5rem;
  flex-shrink: 0;
}

.HeaderPromoBanner .cross-icon:hover {
  background: rgba(255, 255, 255, 0.2784313725);
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderPromoBanner/HeaderPromoBanner.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;AACF;;AACA;EACE,6CAAA;AAEF","sourcesContent":[".HeaderPromoBanner {\r\n  background: #204891;\r\n  padding: 1.2rem 2.4rem;\r\n  padding-right: 4rem;\r\n  text-align: center;\r\n  position: relative;\r\n}\r\n\r\n.HeaderPromoBanner .cross-icon {\r\n  position: absolute;\r\n  right: 1.4rem;\r\n  top: 50%;\r\n  margin-top: -1.1rem;\r\n  width: 2.2rem;\r\n  height: 2.2rem;\r\n  cursor: pointer;\r\n  border-radius: 4px;\r\n  padding: 2px;\r\n  margin-left: 1.5rem;\r\n  flex-shrink: 0;\r\n}\r\n.HeaderPromoBanner .cross-icon:hover {\r\n  background: #ffffff47;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
