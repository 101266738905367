// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GMList .token-table td {
  text-align: left !important;
  width: 20rem !important;
}
.GMList .App-card-info-title {
  display: flex;
  line-height: normal !important;
}
.GMList .GmList-last-column {
  width: 40rem !important;
}
.GMList .GmList-action {
  width: 17rem;
}
.GMList .GmList-last-action {
  margin-left: 1rem;
}
.GMList .Asset-dropdown-container {
  display: inline-block;
}
.GMList .title-small {
  padding-top: 1.5rem;
  margin-bottom: 2.4rem;
}
.GMList .Tooltip-popup {
  min-width: 33rem;
}

@media (max-width: 1100px) {
  .Tab-title-section {
    padding-top: 1.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 800px) {
  .GMList .Tooltip-popup {
    min-width: 27rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/GmList/GmList.scss"],"names":[],"mappings":"AAEI;EACE,2BAAA;EACA,uBAAA;AADN;AAKE;EACE,aAAA;EACA,8BAAA;AAHJ;AAME;EACE,uBAAA;AAJJ;AAOE;EACE,YAAA;AALJ;AAQE;EACE,iBAAA;AANJ;AASE;EACE,qBAAA;AAPJ;AAUE;EACE,mBAAA;EACA,qBAAA;AARJ;AAWE;EACE,gBAAA;AATJ;;AAaA;EACE;IACE,8BAAA;IACA,gCAAA;EAVF;AACF;AAaA;EAEI;IACE,gBAAA;EAZJ;AACF","sourcesContent":[".GMList {\r\n  .token-table {\r\n    td {\r\n      text-align: left !important;\r\n      width: 20rem !important;\r\n    }\r\n  }\r\n\r\n  .App-card-info-title {\r\n    display: flex;\r\n    line-height: normal !important;\r\n  }\r\n\r\n  .GmList-last-column {\r\n    width: 40rem !important;\r\n  }\r\n\r\n  .GmList-action {\r\n    width: 17rem;\r\n  }\r\n\r\n  .GmList-last-action {\r\n    margin-left: 1rem;\r\n  }\r\n\r\n  .Asset-dropdown-container {\r\n    display: inline-block;\r\n  }\r\n\r\n  .title-small {\r\n    padding-top: 1.5rem;\r\n    margin-bottom: 2.4rem;\r\n  }\r\n\r\n  .Tooltip-popup {\r\n    min-width: 33rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 1100px) {\r\n  .Tab-title-section {\r\n    padding-top: 1.5rem !important;\r\n    margin-bottom: 0.5rem !important;\r\n  }\r\n}\r\n\r\n@media (max-width: 800px) {\r\n  .GMList {\r\n    .Tooltip-popup {\r\n      min-width: 27rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
