// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect-wallet-btn {
  background: transparent;
  padding: 0.85rem 1.4rem;
  display: inline-flex;
  align-items: center;
  border: none;
  color: #fff;
  font-size: var(--font-sm);
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid var(--dark-blue-border);
  border-radius: var(--border-radius-sm);
}
.connect-wallet-btn .btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.connect-wallet-btn .btn-label {
  font-weight: 400;
  font-size: var(--font-sm);
  margin-left: 0.8rem;
  letter-spacing: 0;
}
.connect-wallet-btn:hover {
  background: var(--dark-blue-hover);
}
.connect-wallet-btn:active {
  background: var(--dark-blue-active);
}

@media (max-width: 450px) {
  .connect-wallet {
    padding: 0.5rem 0.8rem;
  }
}
@media (max-width: 350px) {
  .connect-wallet-btn {
    height: 3.6rem;
  }
  .connect-wallet-btn .btn-icon {
    display: none;
  }
  .connect-wallet-btn .btn-label {
    margin-left: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Common/ConnectWalletButton.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,uBAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,yCAAA;EACA,sCAAA;AACF;AACE;EACE,oBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACE;EACE,gBAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AACE;EACE,kCAAA;AACJ;AACE;EACE,mCAAA;AACJ;;AAGA;EACE;IACE,sBAAA;EAAF;AACF;AAGA;EACE;IACE,cAAA;EADF;EAEE;IACE,aAAA;EAAJ;EAEE;IACE,gBAAA;EAAJ;AACF","sourcesContent":[".connect-wallet-btn {\r\n  background: transparent;\r\n  padding: 0.85rem 1.4rem;\r\n  display: inline-flex;\r\n  align-items: center;\r\n  border: none;\r\n  color: #fff;\r\n  font-size: var(--font-sm);\r\n  white-space: nowrap;\r\n  cursor: pointer;\r\n  border: 1px solid var(--dark-blue-border);\r\n  border-radius: var(--border-radius-sm);\r\n\r\n  .btn-icon {\r\n    display: inline-flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  .btn-label {\r\n    font-weight: 400;\r\n    font-size: var(--font-sm);\r\n    margin-left: 0.8rem;\r\n    letter-spacing: 0;\r\n  }\r\n  &:hover {\r\n    background: var(--dark-blue-hover);\r\n  }\r\n  &:active {\r\n    background: var(--dark-blue-active);\r\n  }\r\n}\r\n\r\n@media (max-width: 450px) {\r\n  .connect-wallet {\r\n    padding: 0.5rem 0.8rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 350px) {\r\n  .connect-wallet-btn {\r\n    height: 3.6rem;\r\n    .btn-icon {\r\n      display: none;\r\n    }\r\n    .btn-label {\r\n      margin-left: 0px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
