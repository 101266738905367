// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AffiliateStats-claimable-rewards-card .info-card-label {
  margin-bottom: 0;
}
.AffiliateStats-claimable-rewards-card .info-card-data {
  margin-top: 1rem;
}

.AffiliateStats-claimable-rewards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.AffiliateStats-claim-button {
  cursor: pointer;
  display: flex;
  margin-top: 1rem !important;
  font-size: var(--font-md);
  color: #a9a9b0;
}

.Rebate-amount-value {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Referrals/AffiliatesStats.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAFF;;AAKA;EACE,eAAA;EACA,aAAA;EACA,2BAAA;EACA,yBAAA;EACA,cAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;AAFF","sourcesContent":[".AffiliateStats-claimable-rewards-card {\r\n  .info-card-label {\r\n    margin-bottom: 0;\r\n  }\r\n\r\n  .info-card-data {\r\n    margin-top: 1rem;\r\n  }\r\n}\r\n\r\n.AffiliateStats-claimable-rewards-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.AffiliateStats-claim-button {\r\n  cursor: pointer;\r\n  display: flex;\r\n  margin-top: 1rem !important;\r\n  font-size: var(--font-md);\r\n  color: #a9a9b0;\r\n}\r\n\r\n.Rebate-amount-value {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
