// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jobs-page-body {
  /* margin: 3rem 0; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  color: rgba(255, 255, 255, 0.7);
}

.jobs-page-body a {
  color: #b7b7bd;
}

.body-para .subheading {
  margin: 0;
}

.mt-lg {
  margin-top: 2rem;
}

.jobs-contact {
  line-height: 1.5;
}

@media (max-width: 1000px) {
  .jobs-page-body {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Jobs/Jobs.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,aAAa;EACb,qCAAqC;EACrC,cAAc;EACd,+BAA+B;AACjC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".jobs-page-body {\r\n  /* margin: 3rem 0; */\r\n  display: grid;\r\n  grid-template-columns: repeat(2, 1fr);\r\n  grid-gap: 2rem;\r\n  color: rgba(255, 255, 255, 0.7);\r\n}\r\n\r\n.jobs-page-body a {\r\n  color: #b7b7bd;\r\n}\r\n\r\n.body-para .subheading {\r\n  margin: 0;\r\n}\r\n\r\n.mt-lg {\r\n  margin-top: 2rem;\r\n}\r\n\r\n.jobs-contact {\r\n  line-height: 1.5;\r\n}\r\n\r\n@media (max-width: 1000px) {\r\n  .jobs-page-body {\r\n    grid-template-columns: 1fr;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
