// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BuyGMXGLP {
  background: #101124;
  justify-content: space-between;
}

.BuyGMXGLP-container {
  padding: 0 0 3.1rem 0;
  width: 100%;
}

.BuyGMXGLP-container .section-title-content {
  justify-content: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Buy/Buy.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".BuyGMXGLP {\r\n  background: #101124;\r\n  justify-content: space-between;\r\n}\r\n\r\n.BuyGMXGLP-container {\r\n  padding: 0 0 3.1rem 0;\r\n  width: 100%;\r\n}\r\n\r\n.BuyGMXGLP-container .section-title-content {\r\n  justify-content: flex-start;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
