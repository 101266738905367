// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switch-toggle {
  width: 30px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  position: relative;
  opacity: 0.8;
}
.Switch-toggle .handle {
  width: 14.5px;
  height: 14.5px;
  background-color: white;
  border-radius: 40px;
  transition: all 200ms ease-in-out;
  position: absolute;
  left: 2px;
  bottom: 2px;
}
.Switch-toggle.checked {
  background-color: var(--primary-btn-bg);
}
.Switch-toggle.checked .handle {
  left: 14px;
}
.Switch-toggle.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Switch-toggle-wrapper {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-sm);
}
.Switch-toggle-wrapper_disabled .Switch-toggle {
  opacity: 0.5;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/ToggleSwitch/ToggleSwitch.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,0CAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,iCAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AACJ;AAEE;EACE,uCAAA;AAAJ;AAEE;EACE,UAAA;AAAJ;AAGE;EACE,mBAAA;EACA,YAAA;AADJ;;AAKA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;AAFF;AAIE;EACE,YAAA;EACA,mBAAA;AAFJ","sourcesContent":[".Switch-toggle {\r\n  width: 30px;\r\n  height: 18px;\r\n  background-color: rgba(255, 255, 255, 0.4);\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  border-radius: 50px;\r\n  padding: 2px;\r\n  cursor: pointer;\r\n  position: relative;\r\n  opacity: 0.8;\r\n\r\n  .handle {\r\n    width: 14.5px;\r\n    height: 14.5px;\r\n    background-color: white;\r\n    border-radius: 40px;\r\n    transition: all 200ms ease-in-out;\r\n    position: absolute;\r\n    left: 2px;\r\n    bottom: 2px;\r\n  }\r\n\r\n  &.checked {\r\n    background-color: var(--primary-btn-bg);\r\n  }\r\n  &.checked .handle {\r\n    left: 14px;\r\n  }\r\n\r\n  &.disabled {\r\n    cursor: not-allowed;\r\n    opacity: 0.5;\r\n  }\r\n}\r\n\r\n.Switch-toggle-wrapper {\r\n  margin-bottom: 1rem;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  font-size: var(--font-sm);\r\n\r\n  &_disabled .Switch-toggle {\r\n    opacity: 0.5;\r\n    cursor: not-allowed;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
