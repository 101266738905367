// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReferralsTier {
  padding: 20px 30px;
}

.ReferralsTier-form {
  width: 450px;
}

.ReferralsTier-row {
  margin-top: 20px;
}

.ReferralsTier-input {
  padding: 10px;
  border: 1px solid #aaa;
  font-size: 16px;
  width: 100%;
}

.ReferralsTier-label {
  display: block;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ReferralsTier/ReferralsTier.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;AACF","sourcesContent":[".ReferralsTier {\r\n  padding: 20px 30px;\r\n}\r\n\r\n.ReferralsTier-form {\r\n  width: 450px;\r\n}\r\n\r\n.ReferralsTier-row {\r\n  margin-top: 20px;\r\n}\r\n\r\n.ReferralsTier-input {\r\n  padding: 10px;\r\n  border: 1px solid #aaa;\r\n  font-size: 16px;\r\n  width: 100%;\r\n}\r\n\r\n.ReferralsTier-label {\r\n  display: block;\r\n  margin-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
