// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationButton {
  display: flex;
  overflow: hidden;
  border-radius: 0.4rem;
  min-height: 30px;
}
.NavigationButton.clickable {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
.NavigationButton-button {
  display: flex;
  align-items: center;
  flex: 1 1;
  background-color: rgb(52, 55, 86);
}
.clickable .NavigationButton-button:not([disabled]):hover {
  background: rgba(80, 87, 126, 0.6);
}
.clickable .NavigationButton-button:not([disabled]):active {
  background: rgba(80, 87, 126, 0.7);
}
.NavigationButton-button-text {
  flex: 1 1;
  color: white;
  font-size: 1.3rem;
  padding: 0.8rem 1rem;
}
.NavigationButton-button-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
}
.NavigationButton-close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.7rem;
  cursor: pointer;
  background-color: rgb(52, 55, 86);
}
.NavigationButton-close:not([disabled]):hover {
  background: rgba(80, 87, 126, 0.6);
}
.NavigationButton-close:not([disabled]):active {
  background: rgba(80, 87, 126, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/components/NavigationButton/NavigationButton.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,gBAAA;AACF;AACE;EACE,yBAAA;UAAA,iBAAA;EACA,eAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EAEA,iCAAA;AADJ;AAEI;EACE,kCAAA;AAAN;AAGI;EACE,kCAAA;AADN;AAKE;EACE,SAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;AAHJ;AAME;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AAJJ;AAOE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;EAEA,iCAAA;AANJ;AAOI;EACE,kCAAA;AALN;AAQI;EACE,kCAAA;AANN","sourcesContent":[".NavigationButton {\r\n  display: flex;\r\n  overflow: hidden;\r\n  border-radius: 0.4rem;\r\n  min-height: 30px;\r\n\r\n  &.clickable {\r\n    user-select: none;\r\n    cursor: pointer;\r\n  }\r\n\r\n  &-button {\r\n    display: flex;\r\n    align-items: center;\r\n    flex: 1;\r\n\r\n    background-color: rgb(52, 55, 86);\r\n    .clickable &:not([disabled]):hover {\r\n      background: rgba(80, 87, 126, 0.6);\r\n    }\r\n\r\n    .clickable &:not([disabled]):active {\r\n      background: rgba(80, 87, 126, 0.7);\r\n    }\r\n  }\r\n\r\n  &-button-text {\r\n    flex: 1;\r\n    color: white;\r\n    font-size: 1.3rem;\r\n    padding: 0.8rem 1rem;\r\n  }\r\n\r\n  &-button-arrow {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 0 0.8rem;\r\n  }\r\n\r\n  &-close {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 0 0.7rem;\r\n    cursor: pointer;\r\n\r\n    background-color: rgb(52, 55, 86);\r\n    &:not([disabled]):hover {\r\n      background: rgba(80, 87, 126, 0.6);\r\n    }\r\n\r\n    &:not([disabled]):active {\r\n      background: rgba(80, 87, 126, 0.7);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
