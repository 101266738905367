// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PositionEditor-tabs {
  margin-top: 0 !important;
}
.PositionEditor-tabs .Tab {
  margin-top: 0 !important;
}

.PositionEditor-modal .Modal-body {
  overflow-x: hidden;
}

.Edit-collateral-token-selector .Modal .Modal-content {
  width: 38rem;
  max-height: 80vh;
  top: auto;
  bottom: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/PositionEditor/PositionEditor.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;AACE;EACE,wBAAA;AACJ;;AAIE;EACE,kBAAA;AADJ;;AAOI;EACE,YAAA;EACA,gBAAA;EACA,SAAA;EACA,YAAA;AAJN","sourcesContent":[".PositionEditor-tabs {\r\n  margin-top: 0 !important;\r\n\r\n  .Tab {\r\n    margin-top: 0 !important;\r\n  }\r\n}\r\n\r\n.PositionEditor-modal {\r\n  .Modal-body {\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n.Edit-collateral-token-selector {\r\n  .Modal {\r\n    .Modal-content {\r\n      width: 38rem;\r\n      max-height: 80vh;\r\n      top: auto;\r\n      bottom: auto;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
