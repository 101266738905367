// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Orders-toa .Modal-content {
  width: 36rem;
  font-size: var(--font-base);
}

.Orders-toa-accept-rules {
  margin-top: 0.31rem;
}

.Orders-toa-accept-rules .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.Orders-toa-accept-rules .Checkbox-label {
  margin-right: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Exchange/OrdersToa.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,+BAA+B;EAC/B,cAAc;EACd,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".Orders-toa .Modal-content {\r\n  width: 36rem;\r\n  font-size: var(--font-base);\r\n}\r\n\r\n.Orders-toa-accept-rules {\r\n  margin-top: 0.31rem;\r\n}\r\n\r\n.Orders-toa-accept-rules .Checkbox {\r\n  width: 100%;\r\n  display: grid;\r\n  grid-template-columns: 1fr auto;\r\n  direction: rtl;\r\n  font-size: var(--font-sm);\r\n  margin-bottom: 0.465rem;\r\n}\r\n\r\n.Orders-toa-accept-rules .Checkbox-label {\r\n  margin-right: 0.8rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
