// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExchangeWalletTokens {
  max-height: 100%;
  overflow: auto;
}

.ExchangeWalletTokens-token-name {
  margin-right: 1.5rem;
}

.ExchangeWalletTokens-box {
  display: block;
  cursor: pointer;
  border-radius: 0.6rem;
  text-align: right;
  white-space: nowrap;
}

.ExchangeWalletTokens-box:hover {
  color: #8763ff;
}

.ExchangeWalletTokens-token-row {
  cursor: pointer;
  padding: 0.8rem 1.5rem;
}

.ExchangeWalletTokens-token-row:hover {
  background: linear-gradient(90deg, rgba(93, 43, 255, 0.5) 0%, rgba(184, 15, 150, 0.5) 100%);
}

.ExchangeWalletTokens-top-row {
  font-size: var(--font-base);
  display: grid;
  grid-template-columns: auto auto;
}

.ExchangeWalletTokens-content-row {
  margin-top: 0.31rem;
  font-size: var(--font-sm);
  opacity: 0.7;
  display: grid;
  grid-template-columns: auto auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Exchange/ExchangeWalletTokens.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,2FAA2F;AAC7F;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,gCAAgC;AAClC","sourcesContent":[".ExchangeWalletTokens {\r\n  max-height: 100%;\r\n  overflow: auto;\r\n}\r\n\r\n.ExchangeWalletTokens-token-name {\r\n  margin-right: 1.5rem;\r\n}\r\n\r\n.ExchangeWalletTokens-box {\r\n  display: block;\r\n  cursor: pointer;\r\n  border-radius: 0.6rem;\r\n  text-align: right;\r\n  white-space: nowrap;\r\n}\r\n\r\n.ExchangeWalletTokens-box:hover {\r\n  color: #8763ff;\r\n}\r\n\r\n.ExchangeWalletTokens-token-row {\r\n  cursor: pointer;\r\n  padding: 0.8rem 1.5rem;\r\n}\r\n\r\n.ExchangeWalletTokens-token-row:hover {\r\n  background: linear-gradient(90deg, rgba(93, 43, 255, 0.5) 0%, rgba(184, 15, 150, 0.5) 100%);\r\n}\r\n\r\n.ExchangeWalletTokens-top-row {\r\n  font-size: var(--font-base);\r\n  display: grid;\r\n  grid-template-columns: auto auto;\r\n}\r\n\r\n.ExchangeWalletTokens-content-row {\r\n  margin-top: 0.31rem;\r\n  font-size: var(--font-sm);\r\n  opacity: 0.7;\r\n  display: grid;\r\n  grid-template-columns: auto auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
