// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RedirectModal .Modal-content {
  width: 34rem;
  font-size: var(--font-base);
  line-height: 1.9rem;
}

.RedirectModal .Modal-body .Checkbox {
  align-items: flex-start;
}

.RedirectModal .Modal-body .Checkbox-icon-wrapper {
  display: block;
}

.RedirectModal .Modal-body .Checkbox .Checkbox-label {
  margin-left: 0.5rem;
}

@media (max-width: 700px) {
  .RedirectModal .Modal-content {
    width: 90vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalViews/RedirectModal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".RedirectModal .Modal-content {\r\n  width: 34rem;\r\n  font-size: var(--font-base);\r\n  line-height: 1.9rem;\r\n}\r\n\r\n.RedirectModal .Modal-body .Checkbox {\r\n  align-items: flex-start;\r\n}\r\n\r\n.RedirectModal .Modal-body .Checkbox-icon-wrapper {\r\n  display: block;\r\n}\r\n\r\n.RedirectModal .Modal-body .Checkbox .Checkbox-label {\r\n  margin-left: 0.5rem;\r\n}\r\n\r\n@media (max-width: 700px) {\r\n  .RedirectModal .Modal-content {\r\n    width: 90vw;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
