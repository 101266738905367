// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApproveTokenButton {
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-sm);
  direction: ltr !important;
}

.ApproveTokenButton-spin {
  color: #3749e6;
}`, "",{"version":3,"sources":["webpack://./src/components/ApproveTokenButton/ApproveTokenButton.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".ApproveTokenButton {\r\n  width: 100%;\r\n  user-select: none;\r\n  cursor: pointer;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  font-size: var(--font-sm);\r\n  direction: ltr !important;\r\n}\r\n\r\n.ApproveTokenButton-spin {\r\n  color: #3749e6;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
