// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VersionSwitch {
  display: inline-flex;
  background: linear-gradient(90deg, rgba(30, 34, 61, 0.9) 0%, rgba(38, 43, 71, 0.9) 100%);
  border-radius: 3px;
}

.VersionSwitch-option {
  padding: 1.2rem;
  text-decoration: none;
  font-size: var(--font-sm);
  cursor: pointer;
  color: var(--text-gray);
  line-height: 1;
}
.VersionSwitch-option.v1 {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.VersionSwitch-option.v2 {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.VersionSwitch-option:hover {
  color: rgb(255, 255, 255);
  background: rgba(58, 63, 121, 0.5607843137);
}
.VersionSwitch-option.v1.active {
  background: #f3b50c;
  color: #000000;
}
.VersionSwitch-option.v2.active {
  background: var(--primary-btn-bg);
  color: rgb(255, 255, 255);
}

@media (max-width: 1100px) {
  .VersionSwitch-option {
    padding: 0.75rem 1.2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/VersionSwitch/VersionSwitch.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,wFAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,qBAAA;EACA,yBAAA;EACA,eAAA;EACA,uBAAA;EACA,cAAA;AACF;AACE;EACE,2BAAA;EACA,8BAAA;AACJ;AAEE;EACE,4BAAA;EACA,+BAAA;AAAJ;AAGE;EACE,yBAAA;EACA,2CAAA;AADJ;AAIE;EACE,mBAAA;EACA,cAAA;AAFJ;AAKE;EACE,iCAAA;EACA,yBAAA;AAHJ;;AAOA;EACE;IACE,uBAAA;EAJF;AACF","sourcesContent":[".VersionSwitch {\r\n  display: inline-flex;\r\n  background: linear-gradient(90deg, rgba(30, 34, 61, 0.9) 0%, rgba(38, 43, 71, 0.9) 100%);\r\n  border-radius: 3px;\r\n}\r\n\r\n.VersionSwitch-option {\r\n  padding: 1.2rem;\r\n  text-decoration: none;\r\n  font-size: var(--font-sm);\r\n  cursor: pointer;\r\n  color: var(--text-gray);\r\n  line-height: 1;\r\n\r\n  &.v1 {\r\n    border-top-left-radius: 3px;\r\n    border-bottom-left-radius: 3px;\r\n  }\r\n\r\n  &.v2 {\r\n    border-top-right-radius: 3px;\r\n    border-bottom-right-radius: 3px;\r\n  }\r\n\r\n  &:hover {\r\n    color: rgba(255, 255, 255, 1);\r\n    background: #3a3f798f;\r\n  }\r\n\r\n  &.v1.active {\r\n    background: #f3b50c;\r\n    color: #000000;\r\n  }\r\n\r\n  &.v2.active {\r\n    background: var(--primary-btn-bg);\r\n    color: rgba(255, 255, 255, 1);\r\n  }\r\n}\r\n\r\n@media (max-width: 1100px) {\r\n  .VersionSwitch-option {\r\n    padding: 0.75rem 1.2rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
