// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableFilterBase-filter {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.TableFilterBase-filter.active, .TableFilterBase-filter:hover {
  color: #7885ff;
}
.TableFilterBase-filter-popover {
  position: relative;
  z-index: 1000;
  border-radius: var(--border-radius-sm);
  background-color: var(--dark-blue-bg);
  border: 1px solid #32344c;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/TableFilterBase/TableFilterBase.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EAEE,cAAA;AADN;AAKE;EACE,kBAAA;EACA,aAAA;EAEA,sCAAA;EACA,qCAAA;EACA,yBAAA;AAJJ","sourcesContent":[".TableFilterBase {\r\n  &-filter {\r\n    display: inline-flex;\r\n    align-items: center;\r\n    cursor: pointer;\r\n\r\n    &.active,\r\n    &:hover {\r\n      color: #7885ff;\r\n    }\r\n  }\r\n\r\n  &-filter-popover {\r\n    position: relative;\r\n    z-index: 1000;\r\n\r\n    border-radius: var(--border-radius-sm);\r\n    background-color: var(--dark-blue-bg);\r\n    border: 1px solid #32344c;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
