// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Positions-overview {
  font-size: var(--font-sm);
  margin: 1.5rem 3.1rem;
}

.Positions-overview-stats {
  line-height: 1.5;
}

.Positions-overview-table {
  border-collapse: collapse;
}

.Positions-overview th {
  opacity: 0.5;
  font-weight: normal;
  text-align: left;
  padding: 0.8rem 1.5rem 0.8rem 0;
}

.Positions-overview td {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.8rem 1.5rem 0.8rem 0;
}

.Positions-overview .near {
  color: orange;
}
.Positions-overview .near.negative {
  color: var(--error-red);
}
`, "",{"version":3,"sources":["webpack://./src/pages/PositionsOverview/PositionsOverview.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,8CAA8C;EAC9C,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".Positions-overview {\r\n  font-size: var(--font-sm);\r\n  margin: 1.5rem 3.1rem;\r\n}\r\n\r\n.Positions-overview-stats {\r\n  line-height: 1.5;\r\n}\r\n\r\n.Positions-overview-table {\r\n  border-collapse: collapse;\r\n}\r\n\r\n.Positions-overview th {\r\n  opacity: 0.5;\r\n  font-weight: normal;\r\n  text-align: left;\r\n  padding: 0.8rem 1.5rem 0.8rem 0;\r\n}\r\n\r\n.Positions-overview td {\r\n  border-top: 1px solid rgba(255, 255, 255, 0.1);\r\n  padding: 0.8rem 1.5rem 0.8rem 0;\r\n}\r\n\r\n.Positions-overview .near {\r\n  color: orange;\r\n}\r\n.Positions-overview .near.negative {\r\n  color: var(--error-red);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
