// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmationBox-main {
  display: flex;
  flex-direction: column;
}

.trade-info-wrapper {
  display: grid;
  grid-template-columns: 48.5% 3% 48.5%;
  align-items: center;
  margin-top: 0;
}
.trade-info-wrapper .arrow-icon {
  margin-top: 0.5rem;
}
.trade-info-wrapper .trade-token-amount {
  color: white;
  font-size: 1.8rem;
}
.trade-info-wrapper .trade-amount-usd {
  font-size: 1.6rem;
  color: var(--text-gray);
}

.Existing-limit-order {
  font-size: 1.4rem;
}

.Confirmation-box .Modal-body {
  overflow-x: hidden;
}

.ConfirmationBox-approve-tokens {
  display: flex;
  flex-direction: column;
}

.ConfirmationBox-approve-token {
  height: 2.2rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.ConfirmationBox-approve-token:first-child {
  margin-top: 0;
}

.SLTP-pnl-tooltip .Tooltip-popup {
  white-space: nowrap;
  min-width: -moz-fit-content;
  min-width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/ConfirmationBox/ConfirmationBox.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,YAAA;EACA,iBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,uBAAA;AADJ;;AAKA;EACE,iBAAA;AAFF;;AAME;EACE,kBAAA;AAHJ;;AAOA;EACE,aAAA;EACA,sBAAA;AAJF;;AAOA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAJF;AAME;EACE,aAAA;AAJJ;;AAQA;EACE,mBAAA;EACA,2BAAA;EAAA,sBAAA;AALF","sourcesContent":[".ConfirmationBox-main {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.trade-info-wrapper {\r\n  display: grid;\r\n  grid-template-columns: 48.5% 3% 48.5%;\r\n  align-items: center;\r\n  margin-top: 0;\r\n\r\n  .arrow-icon {\r\n    margin-top: 0.5rem;\r\n  }\r\n\r\n  .trade-token-amount {\r\n    color: white;\r\n    font-size: 1.8rem;\r\n  }\r\n\r\n  .trade-amount-usd {\r\n    font-size: 1.6rem;\r\n    color: var(--text-gray);\r\n  }\r\n}\r\n\r\n.Existing-limit-order {\r\n  font-size: 1.4rem;\r\n}\r\n\r\n.Confirmation-box {\r\n  .Modal-body {\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n.ConfirmationBox-approve-tokens {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.ConfirmationBox-approve-token {\r\n  height: 2.2rem;\r\n  display: flex;\r\n  align-items: center;\r\n  margin-top: 0.5rem;\r\n\r\n  &:first-child {\r\n    margin-top: 0;\r\n  }\r\n}\r\n\r\n.SLTP-pnl-tooltip .Tooltip-popup {\r\n  white-space: nowrap;\r\n  min-width: fit-content;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
