// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.net-fees-over-time {
  list-style: none;
  margin: 0;
  padding: 0;
}
.net-fees-over-time li {
  padding-bottom: 0.5rem;
  display: inline-flex;
  margin-right: 0.5rem;
}
.net-fees-over-time li:last-child {
  margin-right: 0;
}
.net-fees-over-time .net-fee__period {
  margin-right: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/MarketNetFee/MarketNetFee.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,SAAA;EACA,UAAA;AACF;AAAE;EACE,sBAAA;EACA,oBAAA;EACA,oBAAA;AAEJ;AACE;EACE,eAAA;AACJ;AACE;EACE,qBAAA;AACJ","sourcesContent":[".net-fees-over-time {\r\n  list-style: none;\r\n  margin: 0;\r\n  padding: 0;\r\n  li {\r\n    padding-bottom: 0.5rem;\r\n    display: inline-flex;\r\n    margin-right: 0.5rem;\r\n  }\r\n\r\n  li:last-child {\r\n    margin-right: 0;\r\n  }\r\n  .net-fee__period {\r\n    margin-right: 0.25rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
