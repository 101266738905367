// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PositionEditor-tabs {
  margin-top: 0;
}

.PositionEditor-fees-tooltip .Tooltip-popup {
  width: 23em;
}

.PositionEditor-subaccount-button {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/OrderEditor/OrderEditor.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".PositionEditor-tabs {\r\n  margin-top: 0;\r\n}\r\n\r\n.PositionEditor-fees-tooltip .Tooltip-popup {\r\n  width: 23em;\r\n}\r\n\r\n.PositionEditor-subaccount-button {\r\n  margin-top: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
