// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-form {
  max-width: 38.75rem;
}

.input-row {
  margin-bottom: 1.5rem;
}

.input-label {
  display: block;
  margin-bottom: 0.8rem;
  font-size: var(--font-base);
}

.text-input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: var(--font-base);
  width: 100%;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Input.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,0CAA0C;EAC1C,2BAA2B;EAC3B,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".input-form {\r\n  max-width: 38.75rem;\r\n}\r\n\r\n.input-row {\r\n  margin-bottom: 1.5rem;\r\n}\r\n\r\n.input-label {\r\n  display: block;\r\n  margin-bottom: 0.8rem;\r\n  font-size: var(--font-base);\r\n}\r\n\r\n.text-input {\r\n  border: 1px solid rgba(255, 255, 255, 0.2);\r\n  font-size: var(--font-base);\r\n  width: 100%;\r\n  box-sizing: border-box;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
