// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NetFeeHeaderTooltipContent-netfee-header-tooltip {
  text-transform: none;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/MarketsList/NetFeeHeaderTooltipContent.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,YAAA;AACF","sourcesContent":[".NetFeeHeaderTooltipContent-netfee-header-tooltip {\r\n  text-transform: none;\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
