// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PositionSeller {
  overflow-x: hidden;
}
.PositionSeller .Modal-body {
  overflow-x: hidden;
}

.PositionSeller-modal {
  overflow-x: hidden;
}

.PositionSeller-info-box {
  overflow-x: hidden;
}

.PositionSeller-price-impact-warning {
  margin-bottom: 0.5rem;
  max-width: 100%;
  overflow: hidden;
}

@media (max-width: 350px) {
  .PositionSeller-receive-row.Exchange-info-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .Exchange-info-row.PositionSeller-receive-row .TokenSelector-box {
    margin-top: 8px;
    text-align: left;
  }
  .PositionSelector-selected-receive-token.align-right {
    margin-top: 8px;
    text-align: left;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/PositionSeller/PositionSeller.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,kBAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;AAAF;;AAGA;EACE;IACE,aAAA;IACA,sBAAA;IACA,uBAAA;EAAF;EAGA;IACE,eAAA;IACA,gBAAA;EADF;EAIA;IACE,eAAA;IACA,gBAAA;EAFF;AACF","sourcesContent":[".PositionSeller {\r\n  overflow-x: hidden;\r\n\r\n  .Modal-body {\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n.PositionSeller-modal {\r\n  overflow-x: hidden;\r\n}\r\n\r\n.PositionSeller-info-box {\r\n  overflow-x: hidden;\r\n}\r\n\r\n.PositionSeller-price-impact-warning {\r\n  margin-bottom: 0.5rem;\r\n  max-width: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\n@media (max-width: 350px) {\r\n  .PositionSeller-receive-row.Exchange-info-row {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n  }\r\n\r\n  .Exchange-info-row.PositionSeller-receive-row .TokenSelector-box {\r\n    margin-top: 8px;\r\n    text-align: left;\r\n  }\r\n\r\n  .PositionSelector-selected-receive-token.align-right {\r\n    margin-top: 8px;\r\n    text-align: left;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
