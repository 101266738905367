// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GmConfirmationBox .Modal-body {
  overflow-x: hidden;
}

.GmConfirmationBox-main {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.GmConfirmationBox-approve-tokens {
  display: flex;
  flex-direction: column;
}

.GmConfirmationBox-approve-token {
  height: 2.2rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.GmConfirmationBox-approve-token:first-child {
  margin-top: 0;
}

.GmConfirmationBox-high-fee {
  margin-bottom: 0.465rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/GmSwap/GmConfirmationBox/GmConfirmationBox.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AADF;AAGE;EACE,aAAA;AADJ;;AAKA;EACE,uBAAA;AAFF","sourcesContent":[".GmConfirmationBox {\r\n  .Modal-body {\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n.GmConfirmationBox-main {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-top: 0;\r\n}\r\n\r\n.GmConfirmationBox-approve-tokens {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.GmConfirmationBox-approve-token {\r\n  height: 2.2rem;\r\n  display: flex;\r\n  align-items: center;\r\n  margin-top: 0.5rem;\r\n\r\n  &:first-child {\r\n    margin-top: 0;\r\n  }\r\n}\r\n\r\n.GmConfirmationBox-high-fee {\r\n  margin-bottom: 0.465rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
