// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PoolSelector2-table {
  border-collapse: collapse;
}
.PoolSelector2-table th {
  text-transform: uppercase;
  font-weight: normal;
  opacity: 0.7;
}
.PoolSelector2-collateral-logos {
  display: flex;
}
.PoolSelector2-collateral-logo-first {
  position: relative;
}
.PoolSelector2-collateral-logo-second {
  margin-left: -12px;
}
.PoolSelector2-column-pool {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.PoolSelector2-column-open-fees {
  text-align: right;
}
.PoolSelector2-mobile-info {
  margin: unset;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.8rem;
}
.PoolSelector2-mobile-info dt {
  color: rgba(255, 255, 255, 0.7);
}
.PoolSelector2-mobile-info dd {
  margin: 0;
  text-align: right;
}
.PoolSelector2-mobile-collateral-logo-second {
  margin-left: -15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/PoolSelector2/PoolSelector2.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAEI;EACE,yBAAA;EACA,mBAAA;EACA,YAAA;AAAN;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;AAOE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AALJ;AAQE;EACE,iBAAA;AANJ;AAUI;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EAEA,8BAAA;EACA,eAAA;AATN;AAWM;EACE,+BAAA;AATR;AAYM;EACE,SAAA;EACA,iBAAA;AAVR;AAcI;EACE,kBAAA;AAZN","sourcesContent":[".PoolSelector2 {\r\n  &-table {\r\n    border-collapse: collapse;\r\n\r\n    th {\r\n      text-transform: uppercase;\r\n      font-weight: normal;\r\n      opacity: 0.7;\r\n    }\r\n  }\r\n\r\n  &-collateral-logos {\r\n    display: flex;\r\n  }\r\n\r\n  &-collateral-logo-first {\r\n    position: relative;\r\n  }\r\n\r\n  &-collateral-logo-second {\r\n    margin-left: calc(-24px / 2);\r\n  }\r\n\r\n  &-column-pool {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 1rem;\r\n  }\r\n\r\n  &-column-open-fees {\r\n    text-align: right;\r\n  }\r\n\r\n  &-mobile {\r\n    &-info {\r\n      margin: unset;\r\n      margin-top: 1rem;\r\n      display: grid;\r\n\r\n      grid-template-columns: 1fr 1fr;\r\n      row-gap: 0.8rem;\r\n\r\n      dt {\r\n        color: rgba(255, 255, 255, 0.7);\r\n      }\r\n\r\n      dd {\r\n        margin: 0;\r\n        text-align: right;\r\n      }\r\n    }\r\n\r\n    &-collateral-logo-second {\r\n      margin-left: calc(-30px / 2);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
