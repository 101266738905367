// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TradeHistoryRow-external-link {
  vertical-align: top;
}
.TradeHistoryRow-external-link svg path {
  fill: currentColor;
}

.TradeHistoryRow-time {
  font-size: 1.25rem;
}

.TradeHistoryRow-account {
  display: block;
  font-size: 1.25rem;
  margin-top: 0.25rem;
  min-width: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TradeHistoryRow-tooltip {
  white-space: pre-line;
}

.TradeHistoryRow-price {
  text-align: end !important;
}

.TradeHistoryRow-tooltip-portal .Tooltip-popup {
  min-width: max-content;
}

@media (min-width: 600px) {
  .TradeHistoryRow-price-tooltip-portal .Tooltip-popup {
    width: 400px;
  }
}

.TradeHistoryRow-action-handle {
  display: block;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/TradeHistory/TradeHistoryRow/TradeHistoryRow.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEI;EACE,kBAAA;AAAN;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,cAAA;EACA,kBAAA;EACA,mBAAA;EAEA,YAAA;EACA,gBAAA;EAEA,gBAAA;EACA,uBAAA;AAJF;;AAOA;EACE,qBAAA;AAJF;;AAOA;EACE,0BAAA;AAJF;;AAQE;EACE,sBAAA;AALJ;;AAWI;EADF;IAEI,YAAA;EAPJ;AACF;;AAWA;EACE,cAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AARF","sourcesContent":[".TradeHistoryRow-external-link {\r\n  vertical-align: top;\r\n\r\n  svg {\r\n    path {\r\n      fill: currentColor;\r\n    }\r\n  }\r\n}\r\n\r\n.TradeHistoryRow-time {\r\n  font-size: 1.25rem;\r\n}\r\n\r\n.TradeHistoryRow-account {\r\n  display: block;\r\n  font-size: 1.25rem;\r\n  margin-top: 0.25rem;\r\n\r\n  min-width: 0;\r\n  white-space: pre;\r\n\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.TradeHistoryRow-tooltip {\r\n  white-space: pre-line;\r\n}\r\n\r\n.TradeHistoryRow-price {\r\n  text-align: end !important;\r\n}\r\n\r\n.TradeHistoryRow-tooltip-portal {\r\n  .Tooltip-popup {\r\n    min-width: max-content;\r\n  }\r\n}\r\n\r\n.TradeHistoryRow-price-tooltip-portal {\r\n  .Tooltip-popup {\r\n    @media (min-width: 600px) {\r\n      width: 400px;\r\n    }\r\n  }\r\n}\r\n\r\n.TradeHistoryRow-action-handle {\r\n  display: block;\r\n  min-width: 0px;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  white-space: nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
