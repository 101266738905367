// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GmAssetDropdown .asset-item {
  width: auto;
}
.GmAssetDropdown .asset-item:hover .subtext {
  color: white;
}
.GmAssetDropdown .wallet-icon {
  width: 1.6rem;
}
.GmAssetDropdown .subtext {
  color: #a0a3c4;
}

@media (max-width: 350px) {
  .GmAssetDropdown .asset-menu-items {
    width: 22rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Synthetics/GmAssetDropdown/GmAssetDropdown.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAGM;EACE,YAAA;AADR;AAKE;EACE,aAAA;AAHJ;AAKE;EACE,cAAA;AAHJ;;AAOA;EAEI;IACE,YAAA;EALJ;AACF","sourcesContent":[".GmAssetDropdown {\r\n  .asset-item {\r\n    width: auto;\r\n\r\n    &:hover {\r\n      .subtext {\r\n        color: white;\r\n      }\r\n    }\r\n  }\r\n  .wallet-icon {\r\n    width: 1.6rem;\r\n  }\r\n  .subtext {\r\n    color: #a0a3c4;\r\n  }\r\n}\r\n\r\n@media (max-width: 350px) {\r\n  .GmAssetDropdown {\r\n    .asset-menu-items {\r\n      width: 22rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
