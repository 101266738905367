// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwapBox-collateral-tooltip-text {
  font-weight: normal;
}

.Exchange-leverage-toggle-wrapper {
  margin-bottom: 0.8rem !important;
}

.Exchange-leverage-slider-settings {
  display: flex;
  justify-content: space-between;
}
.Exchange-leverage-slider-settings .SwapBox-leverage-toggle {
  width: 30px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  position: relative;
}
.Exchange-leverage-slider-settings .SwapBox-leverage-toggle .handle {
  width: 14.4px;
  height: 14.4px;
  background-color: white;
  border-radius: 40px;
  transition: all 200ms ease-in-out;
  position: absolute;
  left: 2px;
}
.Exchange-leverage-slider-settings .SwapBox-leverage-toggle[data-is-on=true] {
  background-color: #3d51ff;
}
.Exchange-leverage-slider-settings .SwapBox-leverage-toggle[data-is-on=true] .handle {
  left: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/Exchange/SwapBox.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,0CAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AACJ;AACI;EACE,aAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,iCAAA;EACA,kBAAA;EACA,SAAA;AACN;AAGE;EACE,yBAAA;AADJ;AAGE;EACE,UAAA;AADJ","sourcesContent":[".SwapBox-collateral-tooltip-text {\r\n  font-weight: normal;\r\n}\r\n\r\n.Exchange-leverage-toggle-wrapper {\r\n  margin-bottom: 0.8rem !important;\r\n}\r\n\r\n.Exchange-leverage-slider-settings {\r\n  display: flex;\r\n  justify-content: space-between;\r\n\r\n  .SwapBox-leverage-toggle {\r\n    width: 30px;\r\n    height: 18px;\r\n    background-color: rgba(255, 255, 255, 0.4);\r\n    display: flex;\r\n    justify-content: flex-start;\r\n    align-items: center;\r\n    border-radius: 50px;\r\n    padding: 2px;\r\n    cursor: pointer;\r\n    position: relative;\r\n\r\n    .handle {\r\n      width: 14.4px;\r\n      height: 14.4px;\r\n      background-color: white;\r\n      border-radius: 40px;\r\n      transition: all 200ms ease-in-out;\r\n      position: absolute;\r\n      left: 2px;\r\n    }\r\n  }\r\n\r\n  .SwapBox-leverage-toggle[data-is-on=\"true\"] {\r\n    background-color: #3d51ff;\r\n  }\r\n  .SwapBox-leverage-toggle[data-is-on=\"true\"] .handle {\r\n    left: calc(15px - 2px);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
