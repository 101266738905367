// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  text-align: center;
}
.section {
  margin-bottom: 4.65rem;
}
.Page-subtitle {
  font-size: var(--font-lg);
  font-weight: 500;
  margin: 1.5rem 0;
}
.content {
  margin-top: 4.65rem;
}
.body-text {
  margin: 0px;
  font-weight: 400;
  font-size: var(--font-base);
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
}

.body-title {
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: var(--font-lg);
}

.list-style-none {
  list-style: none;
}
.list-style-none li {
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/TermsAndConditions/TermsAndConditions.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,2BAA2B;EAC3B,mBAAmB;EACnB,qBAAqB;EACrB,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".center {\r\n  text-align: center;\r\n}\r\n.section {\r\n  margin-bottom: 4.65rem;\r\n}\r\n.Page-subtitle {\r\n  font-size: var(--font-lg);\r\n  font-weight: 500;\r\n  margin: 1.5rem 0;\r\n}\r\n.content {\r\n  margin-top: 4.65rem;\r\n}\r\n.body-text {\r\n  margin: 0px;\r\n  font-weight: 400;\r\n  font-size: var(--font-base);\r\n  line-height: 2.4rem;\r\n  margin-bottom: 1.5rem;\r\n  color: rgba(255, 255, 255, 0.7);\r\n}\r\n\r\n.body-title {\r\n  font-weight: 500;\r\n  margin-bottom: 1.5rem;\r\n  font-size: var(--font-lg);\r\n}\r\n\r\n.list-style-none {\r\n  list-style: none;\r\n}\r\n.list-style-none li {\r\n  padding-bottom: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
